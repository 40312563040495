<template>
  <div>
    <loader
      v-if="loader"
      :row-num="1"
    />
    <b-row
      v-else
      class="summary-detail mx-0"
    >
      <!-- <div class="summary-des"> -->
      <!-- <span v-show="isshow">{{ projectdetail }} </span> -->
      <!-- </div> -->
      <!-- project duration -->
      <b-col
        cols="12"
        class="d-flex justify-content-between px-0"
      >
        <div><p class="projectOverview">
          Timeline Overview
        </p></div>
        <div
          v-if="editRights"
          class="icons"
        >
          <span
            v-if="edit === true"
            @click="updateTimeline(), (edit = false), (isshow = true)"
          >
            <feather-icon
              icon="CheckIcon"
              class="edit-icons-summary mr-50"
              size="30"
            />
          </span>
          <span
            v-if="edit === true"
            @click="(edit = false), (isshow = true)"
          >
            <feather-icon
              icon="XIcon"
              size="30"
              class="edit-icons-summary"
            /></span>
          <span
            v-if="edit === false"
            @click="(edit = true), editMethod()"
          >
            <feather-icon
              icon="Edit3Icon"
              size="30"
              class="edit-icons-summary"
            />
          </span>
        </div>
      </b-col>
      <b-col
        cols="12"
        class="date-list-view px-0"
      >
        <validation-observer ref="clientObserver">
          <ul class="d-flex p-0 flex-wrap">
            <li>
              <p class="list-title">
                Estimated Start Date
              </p>
              <p
                v-show="isshow"
                class="list-item d-flex align-items-center"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                  color="#888"
                  class="mr-25"
                />
                start: {{ dayName(estimated_start_date) }}
              </p>

              <validation-provider
                #default="{ errors }"
                name="Estimated start date"
                rules="required"
              >
                <b-input-group
                  v-show="!isshow"
                  id=""
                  class="input-group-merge"
                  name="eaststartdate"
                >
                  <flat-pickr
                    v-model="estimated_start_date"
                    :config="configEstimatedStartDate"
                    class="form-control"
                    @on-change="onEstimatedStartDateChange"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="CalendarIcon" />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-show="!isshow"
                  class="text-danger"
                >{{
                  errors[0]
                }}</small>
              </validation-provider>
            </li>
            <li>
              <p class="list-title">
                Estimated End Date
              </p>
              <p
                v-show="isshow"
                class="list-item d-flex align-items-center"
              >
                <feather-icon
                  icon="TargetIcon"
                  size="16"
                  color="#888"
                  class="mr-25"
                />
                End: {{ dayName(estimated_end_date) }}
              </p>
              <validation-provider
                #default="{ errors }"
                name="Estimated end date"
                rules="required"
              >
                <b-input-group
                  v-show="!isshow"
                  id=""
                  class="input-group-merge"
                  name="eastenddate"
                >
                  <flat-pickr
                    v-model="estimated_end_date"
                    :config="configEstimatedEndDate"
                    class="form-control"
                    @on-change="onEstimatedEndDateChange"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="CalendarIcon" />
                  </b-input-group-append>
                </b-input-group>

                <small
                  v-show="!isshow"
                  class="text-danger"
                >{{
                  errors[0]
                }}</small>
              </validation-provider>
            </li>
            <li>
              <p class="list-title">
                Actual Start Date
              </p>
              <p
                v-show="isshow"
                class="list-item d-flex align-items-center"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                  color="#888"
                  class="mr-25"
                />
                start: {{ dayName(actual_start_date) }}
              </p>
              <validation-provider
                #default="{ errors }"
                name="Actual start date"
                rules="required"
              >
                <b-input-group
                  v-show="!isshow"
                  id=""
                  class="input-group-merge"
                  name="actualstartdate"
                >
                  <flat-pickr
                    v-model="actual_start_date"
                    :config="configActualStartDate"
                    class="form-control"
                    @on-change="onActualStartDateChange"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="CalendarIcon" />
                  </b-input-group-append>
                </b-input-group>

                <small
                  v-show="!isshow"
                  class="text-danger"
                >{{
                  errors[0]
                }}</small>
              </validation-provider>
            </li>
            <li>
              <p class="list-title">
                Actual End Date
              </p>
              <p
                v-show="isshow"
                class="list-item d-flex align-items-center"
              >
                <feather-icon
                  icon="TargetIcon"
                  size="16"
                  color="#888"
                  class="mr-25"
                />
                End: {{ dayName(actual_end_date) }}
              </p>
              <b-input-group
                v-show="!isshow"
                id=""
                class="input-group-merge"
                name="actualenddate"
              >
                <flat-pickr
                  v-model="actual_end_date"
                  :config="configActualEndDate"
                  class="form-control"
                  @on-change="onActualEndDateChange"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="CalendarIcon" />
                </b-input-group-append>
              </b-input-group>
            </li>
          </ul>
        </validation-observer>
      </b-col>

    </b-row>
    <QaAssureReport />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { eventBus } from '@/main'
import Loader from '@/components/loaders/timesheet-loader.vue'
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'
import QaAssureReport from './QaAssureReport.vue'

export default {
  name: 'TimelineInfo',
  components: {
    FeatherIcon,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    QaAssureReport,
    Loader,
  },
  props: {
    projectDtl: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isshow: true,
      loader: false,
      edit: false,
      data: null,
      actual_end_date: null,
      actual_start_date: null,
      estimated_start_date: null,
      estimated_end_date: null,
      configEstimatedStartDate: {
        maxDate: null,
        dateFormat: 'Y-m-d',
      },
      configEstimatedEndDate: {
        minDate: null,
        dateFormat: 'Y-m-d',
      },
      configActualStartDate: {
        // minDate: new Date(),
        maxDate: null,
        dateFormat: 'Y-m-d',
      },
      configActualEndDate: {
        // minDate: new Date(),
        minDate: null,
        dateFormat: 'Y-m-d',
      },
    }
  },
  computed: {
    editRights() {
      if (this.userInfo && this.userInfo.role == 'SA') {
        return true
      } if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const user = this.projectDtl.project_user.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights == 'project_owner'
            || user.access_rights == 'co_ordinator')
        ) {
          return true
        }
        // access_rights = project_owner, co_ordinator, member
      }
      return false
    },
  },
  watch: {
    projectDtl(nv) {
      if (nv) {
        this.getprojectTimelinedetail()
      }
    },
  },
  mounted() {
    this.getprojectTimelinedetail()
  },
  methods: {
    editMethod() {
      this.isshow = false
    },
    /**
     * check the form validation
     */
    updateTimeline() {
      this.$refs.clientObserver.validate().then(success => {
        if (success) {
          this.updateTimelineDetails()
        } else {
          this.isshow = false
          this.edit = true
        }
      })
    },

    userdata(data, user_id) {
      let a = null
      if (data && data.project_user && data.project_user.length) {
        const b = data.project_user
        for (let i = 0; i < b.length; i++) {
          if (data.project_user[i].users.id === user_id) {
            a = data.project_user[i].access_rights
          }
        }
      }
      return a
    },

    showToast(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'successfuly Updated',
            variant,
          },
        },
        {
          position,
        },
      )
    },

    /* day's name */
    dayName(date) {
      if (date === null || date === '') {
        return 'N/A'
      }
      const d = new Date(date)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
      // console.log(`${da}-${mo}-${ye}`);
      const dayName = `${da} ${mo}, ${ye}`
      return dayName
    },
    /*  get project detail */
    async getprojectTimelinedetail() {
      if (this.projectDtl) {
        const data = this.projectDtl
        this.data = data
        this.id = data.id
        this.estimated_start_date = data.estimated_start_date
        this.estimated_end_date = data.estimated_end_date
        this.actual_start_date = data.actual_start_date
        this.actual_end_date = data.actual_end_date
      }
    },

    async updateTimelineDetails() {
      this.loader = true
      const input = {
        project_id: this.id,
        estimated_start_date: this.estimated_start_date,
        estimated_end_date: this.estimated_end_date,
        actual_start_date: this.actual_start_date,
        actual_end_date: this.actual_end_date,
      }
      const response = await this.getHTTPPutResponse(
        `project/update-timeline/${this.id}`,
        input,
        true,
      )
      this.getprojectTimelinedetail(this.id)
      if (response.status == '200') {
        // console.log("this.id", this.id);
        this.edit = false
        this.isshow = true
        eventBus.$emit('reloadProjectDtl', true)
        this.getprojectTimelinedetail(this.id)
      }
      this.loader = false
    },
    onEstimatedStartDateChange(selectedDates, dateStr) {
      this.$set(this.configEstimatedEndDate, 'minDate', dateStr)
    },
    onEstimatedEndDateChange(selectedDates, dateStr) {
      this.$set(this.configEstimatedStartDate, 'maxDate', dateStr)
    },
    onActualStartDateChange(selectedDates, dateStr) {
      this.$set(this.configActualEndDate, 'minDate', dateStr)
    },
    onActualEndDateChange(selectedDates, dateStr) {
      this.$set(this.configActualStartDate, 'maxDate', dateStr)
    },
  },
}
</script>

<style></style>
