<template>
  <div class="right-list-plan">
    <div
      v-for="suite in getSelectedSuiteData"
      :key="suite.id"
      class="custom-accordien mb-1"
    >
      <div
        v-if="suite.testcases.length"
        class="upper-header"
      >
        <p
          v-b-toggle="`collapse-${suite.id}`"
          class="custome-btn"
        >
          <img
            src="../../../assets/images/icons/Arrow-Side.svg"
            class="img-fluid img-1"
          >
          <img
            src="../../../assets/images/icons/Arrow-Down.svg"
            class="img-fluid img-2"
          >
          {{ suite.suite_name }}
        </p>
      </div>
      <b-collapse
        :id="`collapse-${suite.id}`"
        class="nested-list"
      >
        <b-card
          key="testId"
          class="pb-1 mb-0"
        >
          <b-card-text
            v-for="(item, index) in suite.testcases"
            :key="index"
            class="card-text"
          >
            <div class="align-items-center d-flex justify-content-between">
              <p class="app-name">
                {{ item.title }}
              </p>
              <p
                v-if="item.last_tested_date"
                class="app-time"
              >
                Last run:
                {{
                  item.last_tested_date
                    ? DDMMYYYYHHMMSSformat(item.last_tested_date)
                    : null
                }}
              </p>
              <p
                v-else
                class="mr-3"
              >
                Not Tested Yet
              </p>
            </div>
            <hr>
          </b-card-text>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>
<script>

import moment from 'moment'

export default {
  name: 'TestPlanCases',
  components: {

  },
  data() {
    return {
      search: null,
      selectedSuiteId: [],
      testCaseList: [],
      selectedTestCaseId: [],
    }
  },
  computed: {
    getSelectedSuiteData() {
      const suiteSelectedData = this.$store.state.app.suiteList.filter(item => this.selectedSuiteId.includes(item.id))

      const selectedTestcastList = this.testCaseList.filter(item => this.selectedTestCaseId.includes(item.id))

      const suiteDataWithMappedTestcases = suiteSelectedData.map(suite => {
        const suiteTestcases = selectedTestcastList.filter(
          testcase => testcase.suite_id === suite.id,
        )
        return {
          ...suite,
          testcases: suiteTestcases,
        }
      })
      return suiteDataWithMappedTestcases
    },
  },
  mounted() {
    //
    if (
      this.$store.state.app.testCaseId
      && this.$store.state.app.testCaseId.length > 0
    ) {
      this.selectedTestCaseId = this.$store.state.app.testCaseId
      this.selectedSuiteId = this.$store.state.app.testPlanFormData.suiteId
      this.getSelectedSuiteTestCaseData()
    }
  },

  destroyed() {
    if (this.$route.params.id4 == 'test-plan') {
      // When open test-suite & test-case selection page than before filter out suiteId
      const suiteIdsWithTestCases = this.getSelectedSuiteData
        .filter(suite => suite.testcases.length > 0) // Filter suites with test cases
        .map(suite => suite.id) // Extract suite IDs

      this.$store.state.app.testPlanFormData.suiteId = suiteIdsWithTestCases
    } else if (this.$route.params.id4 !== 'test-plan') {
      this.$store.commit('app/UPDATE_TEST_CASE_ID', [])
      this.$store.commit('app/UPDATE_TEST_PLAN_FORM_DATA', [])
    }
    this.$store.state.app.suiteId = null
  },
  methods: {
    DDMMYYYYHHMMSSformat(value) {
      return value
        ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm')
        : null
    },
    /**
     * get selected suite test cases
     */
    async getSelectedSuiteTestCaseData() {
      const input = {
        project_id: this.$route.params.id3 ? this.$route.params.id3 : null,
        suite_id:
          this.selectedSuiteId && this.selectedSuiteId.length
            ? this.selectedSuiteId
            : [],
        search: this.search ? this.search : null,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-case',
        input,
        false,
      )

      if (response && response.status == 200) {
        const data = response.data.test_case
        this.testCaseList = Object.values(data).flat()

        if (this.selectedTestCaseId && this.selectedTestCaseId.length > 0) {
          this.selectedTestCaseId = this.selectedTestCaseId.filter(
            elementId => this.testCaseList.find(item => item.id === elementId),
          )
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.hr-line-dashboard {
  width: 100%;
  background-color: #ebe9f1;
}

@import "../../../assets/scss/component-css/tesing.css";
</style>
