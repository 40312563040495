<template>
  <div>
    <validation-observer ref="defectObserver">
      <div>
        <!--filter start-->

        <div class="card-filter-section py-1">
          <div
            class="d-flex align-items-center flex-wrap justify-content-between"
          >
            <div class="d-flex align-items-center">
              <button
                v-if="isbackButtonShow"
                class="back-button btn btn-secondary mr-2"
                @click="back()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  width="18"
                  height="18"
                />
                <span>Back</span>
              </button>
              <p class="filter-title ml-50">
                {{
                  actionTypeCheck
                    ? "Details"
                    : $store.state.app.testActiveName + " Defect"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!actionTypeCheck"
        class="test-form"
      >
        <div>
          <b-form @submit.prevent>
            <b-row>
              <!-- first name -->
              <b-col
                v-if="$store.state.app.defect.code"
                cols="4"
              >
                <b-form-group
                  label="Defect ID"
                  label-for="v-defect-id"
                >
                  <b-form-input
                    id="v-defect-id"
                    v-model="$store.state.app.defect.code"
                    type="text"
                    placeholder="Defect ID"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Defect Title"
                  label-for="v-defect-title"
                  class="required-form-filed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Defect title"
                    rules="required|min:1|max:512"
                  >
                    <b-form-input
                      id="v-defect-title"
                      v-model="$store.state.app.defect.defect_title"
                      type="text"
                      placeholder="Defect Title"
                      :disabled="actionTypeCheck"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                class="modal-select"
              >
                <b-form-group
                  label="Severity"
                  label-for="Severity"
                  class="required-form-filed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Severity"
                    rules="required"
                  >
                    <v-select
                      id="Severity"
                      v-model="$store.state.app.defect.severity"
                      :options="defectSeverityOptions"
                      label="name"
                      :reduce="(priority) => priority.value"
                      class="select-size-lg"
                      name="Severity"
                      placeholder="select Severity"
                      :disabled="actionTypeCheck"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                class="modal-select"
              >
                <b-form-group
                  label="Test Suite"
                  label-for="testSuite"
                  class="required-form-filed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Test Suite"
                    rules="required"
                  >
                    <v-select
                      id="testSuite"
                      v-model="$store.state.app.defect.test_suite"
                      :options="suiteListOption"
                      label="suite_name"
                      :reduce="(priority) => priority.id"
                      class="select-size-lg"
                      name="Assignee"
                      placeholder=" select test suite"
                      :disabled="actionTypeCheck"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                class="modal-select"
              >
                <b-form-group
                  label="Defect Type"
                  label-for="type"
                  class="required-form-filed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Defect Type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="$store.state.app.defect.defect_type"
                      :options="defectOptions"
                      label="name"
                      :reduce="(priority) => priority.value"
                      class="select-size-lg"
                      name="type"
                      placeholder=" select defect type"
                      :disabled="actionTypeCheck"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                class="modal-select"
              >
                <b-form-group
                  label="Assignee"
                  label-for="Assignee"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Assignee"
                    rules=""
                  >
                    <v-select
                      id="Assignee"
                      v-model="$store.state.app.defect.assignee"
                      :options="projectUsers"
                      label="name"
                      :reduce="(priority) => priority.value"
                      class="select-size-lg"
                      name="Assignee"
                      placeholder=" select Assignee"
                      :disabled="actionTypeCheck"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="description">
                  <label
                    for="textarea-default"
                  >Issue Description<span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Issue Description"
                    description
                    rules="required|required_rule_for_editor|validateNoImage||max:2000"
                  >
                    <quill-editor
                      v-show="!actionTypeCheck"
                      id="issue_discription"
                      v-model="$store.state.app.defect.issue_discription"
                      name="issue discription"
                      :options="descriptionEditorOption"
                    >
                      <div
                        id="toolbar1"
                        slot="toolbar"
                      >
                        <!-- Add font size dropdown -->
                        <select class="ql-size">
                          <option value="small" />
                          <!-- Note a missing, thus falsy value, is used to reset to default -->
                          <option selected />
                          <option value="large" />
                          <option value="huge" />
                        </select>

                        <select class="ql-font">
                          <option selected="selected" />
                          <option value="serif" />
                          <option value="monospace" />
                        </select>
                        <!-- Add a bold button -->
                        <button class="ql-bold">
                          Bold
                        </button>
                        <button class="ql-italic">
                          Italic
                        </button>
                        <button class="ql-underline">
                          Underline
                        </button>

                        <button class="ql-clean" />
                        <select class="ql-align">
                          <option selected="selected" />
                          <option value="center" />
                          <option value="right" />
                          <option value="justify" />
                        </select>
                        <button
                          id="ql-link"
                          class="ql-link"
                        >
                          <feather-icon
                            icon="Link2Icon"
                            color="white"
                          />
                        </button>

                        <button
                          class="ql-list"
                          value="ordered"
                        />
                        <button
                          class="ql-list"
                          value="bullet"
                        />
                        <!-- You can also add your own -->
                      </div>
                    </quill-editor>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- <p>Issue Description</p> -->
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <fileUpload
                  v-if="!getDataLoader"
                  :action-type-check="actionTypeCheck"
                  :manual-file-added="attachments"
                  @onChange="handleFileUpload($event)"
                  @onFileRemove="remove_attachments = $event"
                />
              </b-col>
            </b-row>
          </b-form>
          <div class="scenario-steps mt-2">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <div>
                    <label
                      for="textarea-default"
                    >Steps To Reproduce<span
                      class="text-danger"
                    >*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Step to reproduce"
                      rules="required|required_rule_for_editor|validateNoImage||max:2000"
                    >
                      <quill-editor
                        v-show="!actionTypeCheck"
                        id="step_to_reproduce"
                        v-model="$store.state.app.defect.step_to_reproduce"
                        rows="4"
                        name="Step to reproduce"
                        :options="editorOption"
                      >
                        <div
                          id="toolbar"
                          slot="toolbar"
                        >
                          <!-- Add font size dropdown -->
                          <select class="ql-size">
                            <option value="small" />
                            <!-- Note a missing, thus falsy value, is used to reset to default -->
                            <option selected />
                            <option value="large" />
                            <option value="huge" />
                          </select>

                          <select class="ql-font">
                            <option selected="selected" />
                            <option value="serif" />
                            <option value="monospace" />
                          </select>
                          <!-- Add a bold button -->
                          <button class="ql-bold">
                            Bold
                          </button>
                          <button class="ql-italic">
                            Italic
                          </button>
                          <button class="ql-underline">
                            Underline
                          </button>

                          <button class="ql-clean" />
                          <select class="ql-align">
                            <option selected="selected" />
                            <option value="center" />
                            <option value="right" />
                            <option value="justify" />
                          </select>
                          <button
                            id="ql-link"
                            class="ql-link"
                          >
                            <feather-icon
                              icon="Link2Icon"
                              color="white"
                            />
                          </button>

                          <button
                            class="ql-list"
                            value="ordered"
                          />
                          <button
                            class="ql-list"
                            value="bullet"
                          />
                          <!-- You can also add your own -->
                        </div>
                      </quill-editor>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- <p>Steps To Reproduce</p> -->
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-show="false">
                  <p class="deatil mt-50">
                    Add Details
                  </p>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>
        <div
          v-if="$store.state.app.testActiveName != 'View'"
          class="mt-2 position-relative zindex-4"
        >
          <b-button
            class="cancelBtn mr-1"
            type="reset"
            :disabled="loader"
            @click="back()"
          >
            cancel
          </b-button>
          <b-button
            type="submit"
            class="mr-1 save-btn"
            :disabled="loader"
            @click="validationForm()"
          >
            <b-spinner
              v-if="loader"
              small
              class="spinner"
            />
            {{
              this.$store.state.app.testActiveName == "Edit"
                ? "Update"
                : "Create"
            }}
          </b-button>
        </div>
        <!-- Status Update -->
        <div
          v-else
          class="p-1"
        >
          <!-- <b-button type="submit" class="mr-1 save-btn"> Back </b-button> -->
        </div>
      </div>
    </validation-observer>
    <ViewDefect
      v-if="actionTypeCheck"
      :loader="loader"
    />

    <!-- comment contain start -->
    <div v-if="actionTypeCheck">
      <div class="activity-logs mt-1 mb-2">
        <div class="">
          <h3>Comment</h3>
          <hr>
          <div v-for="(item,index) in $store.state.app.defect.comment" :key="index">
            <div class="logs-content mb-2 view-steps">
              <span class="mr-1">
                <b-avatar
                  size="26px"
                  class="ml-25 noteAvatar"
                  :src="item.user.picture"
                  :text="item.user.full_name | avatarText"
                />
              </span>
              <span>{{ item.user.full_name }}</span>
              <div
                class="ml-3"
                v-html="item.comment"
              />
            </div>
          </div>
          <div
            v-if="$store.state.app.defect.comment.length == 0"
            class="d-flex align-items-center"
          >
            <h4>No content added yet</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- comment contain end -->

    <!-- Button's start -->
    <div
      v-if="actionTypeCheck"
      class="mt-25 my-2 position-relative zindex-4"
    >
      <div
        v-if="['reopen', 'open'].includes($store.state.app.defect.status)"
        class="defectBtn"
      >
        <b-button
          class="mr-1 resolve"
          :disabled="loader"
          @click="changeStatus('resolve')"
        >
          Resolved
        </b-button>

        <b-button
          class="mr-1 not-a-issue"
          :disabled="loader"
          @click="changeStatus('not_an_issue')"
        >
          Not An Issue
        </b-button>
      </div>
      <div
        v-if="
          ['resolve', 'not_an_issue', 'close'].includes(
            $store.state.app.defect.status
          )
        "
        class="defectBtn"
      >
        <b-button
          v-show="$store.state.app.defect.status != 'close'"
          class="mr-1 close-status"
          :disabled="loader"
          @click="changeStatus('close')"
        >
          Close
        </b-button>
        <b-button
          class="mr-1 not-a-issue"
          :disabled="loader"
          @click="changeStatus('reopen')"
        >
          Reopen
        </b-button>
      </div>
    </div>
    <!-- Button's end -->

    <!-- Comment modal start -->
    <CommentModal
      :status="newStatus"
      @updateData="updateStatus"
    />
    <!-- Comment modal end -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import ViewDefect from './viewDefect.vue'
import CommentModal from './Modal/CommentModal.vue'
import fileUpload from '../../../components/FileUpload/index.vue'

export default {
  name: 'CreateDefect',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationObserver,
    ValidationProvider,
    quillEditor,
    ViewDefect,
    CommentModal,
    fileUpload,
  },
  data() {
    return {
      loader: false,
      defectOptions: [
        { value: 'bug', name: 'Bug' },
        { value: 'improvement', name: 'Improvement' },
      ],
      task_status: null,
      selectedFileName: '',
      previewImage: null,
      file: [],
      remove_attachments: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/task/image-store`,
        maxFiles: 20,
        uploadMultiple: true,
        parallelUploads: 100,
        maxFilesize: 10,
        // thumbnailWidth: 150,
        addRemoveLinks: true,
        acceptedFiles:
          '.jpeg, .jpg, .png, .txt, .pdf, .ppt,.pptx, .doc, .csv, .docx, .xls, .xlsx',
        dictDefaultMessage:
          "<b class='drop-title'>Drag & drop files</b> <br> <p class='drop-req'>Supported formats: JPEG, PNG, JPG, <br /> TXT, PDF, PPT, DOC, CSV, DOCX, XLS, XLSX <br /> ",
      },
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'Type anything',
      },
      descriptionEditorOption: {
        modules: {
          toolbar: '#toolbar1',
        },
        placeholder: 'Type anything',
      },
      addIconShow: false,
      newStatus: null,
      errorFileList: [],
      isbackButtonShow: true,
      isValidFile: true,
      FileErrorMsg: null,
      attachments: [],
      getDataLoader: false,
    }
  },
  computed: {
    actionTypeCheck() {
      if (this.$store.state.app.testActiveName == 'View') {
        return true
      }
      return false
    },
    projectUsers() {
      this.$store.state.app.projectUsers.forEach(element => {
        element.name = element.full_name
        element.value = element.id
      })
      return this.$store.state.app.projectUsers
    },
    suiteListOption() {
      const suiteList = this.$store.state.app.suiteListWithoutTestCase
      suiteList.forEach(element => {
        element.label = element.suite_name
        element.value = element.id
      })
      return suiteList
    },
  },
  // mounted() {
  //   this.clear();
  //   this.$store.state.app.backAction = this.$store.state.app.backAction
  //     ? this.$store.state.app.backAction
  //     : this.$route.params.id3;
  //   this.$store.state.app.testActiveName = this.$store.state.app.testActiveName
  //     ? this.$store.state.app.testActiveName
  //     : this.$route.params.id4;

  //   this.$store.state.app.testActiveName =
  //     this.$store.state.app.testActiveName[0].toUpperCase() +
  //     this.$store.state.app.testActiveName.slice(1);

  //   if (this.$route.params.id4 != "create" && this.$route.params.id5) {
  //     this.getDefect(this.$route.params.id5);
  //   }

  //   if (
  //     !this.$store.state.app.suiteListWithoutTestCase ||
  //     !this.$store.state.app.suiteListWithoutTestCase.length
  //   ) {
  //     this.getSuiteList();
  //   }
  // },

  mounted() {
    this.clear()

    const { app } = this.$store.state
    const { id4, id5, id6 } = this.$route.params
    const { test_case, suite_id } = this.$route.query

    app.backAction = app.backAction || id4
    app.testActiveName = app.testActiveName || id5

    if (app.testActiveName) {
      app.testActiveName = app.testActiveName[0].toUpperCase() + app.testActiveName.slice(1)
      app.testActiveName = app.testActiveName[0].toUpperCase() + app.testActiveName.slice(1)
    }

    if (id4 !== 'create' && id6) {
      this.getDefect(id6)
      this.isbackButtonShow = !!(this.$route.query
        && Object.keys(this.$route.query).length
        && Object.keys(this.$route.query).length > 1)
    }

    if (id5 == 'create' && test_case && suite_id) {
      app.defect.severity = 'medium'
      app.defect.test_suite = suite_id
    }
    this.getSuiteList()
  },
  destroyed() {
    this.$store.state.app.backAction = null
    this.$store.state.app.defect.id = null
    this.$store.state.app.backAction = null
    this.$store.state.app.defect.id = null
  },
  methods: {
    back() {
      // this.$router.back()
      const {
        test_case, suite_id, testRunDefect, layout,
      } = this.$route.query

      if (test_case && suite_id && testRunDefect) {
        this.$router.push({
          name: 'projectTask',
          params: {
            ...this.$route.params,
            id4: this.$route.query.page,
            id5: this.$route.query.testRunId,
            id6: this.$route.query.testRunCaseId,
            id7: null,
          },
          query: this.$route.query,
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      } else if (layout === 'full') {
        const currentLayout = this.getPreviousUrlQueryParam('layout')
        if (!currentLayout) {
          this.$router.push({
            name: 'projectTask',
            params: {
              ...this.$route.params,
              id4: 'defects',
              id5: null,
              id6: null,
            },
            query: { ...this.$route.query, layout: 'full' },
          }).catch(err => {
            // Ignore the NavigationDuplicated error
            if (err.name !== 'NavigationDuplicated') {
              console.log(err)
            }
          })
        }
      } else {
        this.$router.back()
      }
    },

    async getSuiteList() {
      const input = {
        project_id: this.$route.params.id3 ? this.$route.params.id3 : null,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-suite',
        input,
        false,
      )

      if (response && response.status == 200) {
        const data = response.data && response.data.test_suite
          ? response.data.test_suite
          : []
        this.suiteList = data
        this.$store.commit('app/UPDATE_SUITE_LIST_WITHOUT_TESTCASE', data)
      }
    },
    async getDefect(id) {
      this.loader = true
      this.getDataLoader = true
      const response = await this.getHTTPGetResponse(
        `project/defect/view/${id}`,
        null,
        false,
      )
      if (response && response.status == 200) {
        const data = response.data.defect
        this.attachments = data.defect_attachments
        const defect = {
          id,
          defect_title: data.defect_title,
          severity: data.severity,
          issue_discription: data.issue_discription,
          step_to_reproduce: data.step_to_reproduce,
          attachment: data.defect_attachments,
          removedattachment: [],
          assignee: data.assignee,
          code: data.code,
          status: data.status,
          test_suite: data?.suite_id,
          defect_type: data?.defect_type,
          comment: data.defect_comments,
        }
        this.$store.state.app.defect = defect
      }
      this.loader = false
      this.getDataLoader = false
    },

    /**
     * check the form validation
     * @returns if success true then call saveClientDtl Method
     */
    validationForm() {
      this.$refs.defectObserver.validate().then(success => {
        if (success) {
          this.createDefect()
        }
      })
    },

    /**  create or update Defect */

    async createDefect() {
      this.loader = true
      const formData = {
        defect_title: this.$store.state.app.defect.defect_title,
        project_id: this.$route.params.id3,
        severity: this.$store.state.app.defect.severity,
        issue_discription: this.$store.state.app.defect.issue_discription,
        step_to_reproduce: this.$store.state.app.defect.step_to_reproduce,
        suite_id: this.$store.state.app.defect.test_suite,
        defect_type: this.$store.state.app.defect.defect_type,
        files: this.file ? await Promise.all(this.file.map(async file => ({
          name: file.name,
          file: await this.convertFileToBase64(file),
        }))) : [],
        status: this.$store.state.app.defect.status ?? '',
      }
      // Conditionally add `test_run_case_id` from query parameters
      if (this.$route.query && this.$route.query.test_case) {
        formData.test_run_case_id = this.$route.query.test_case
      }
      if (this.$store.state.app.defect.assignee) {
        formData.assignee = this.$store.state.app.defect.assignee ?? ''
      }

      let response
      if (this.$store.state.app.defect.id) {
        if (this.remove_attachments && this.remove_attachments.length) {
          formData.remove_file_ids = [...this.remove_attachments]
        }

        formData._method = 'PUT'
        response = await this.getHTTPPostResponse(
          `project/defect/update/${this.$store.state.app.defect.id}`,
          formData,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'project/defect/create',
          formData,
          true,
        )
      }

      if (response && response.status == 200) {
        if (this.$route.params.id4 == 'create') {
          this.$store.state.app.defectFilter.assignee = null
          this.$store.state.app.defectFilter.status = ['open', 'reopen']
          this.$store.state.app.defectFilter.currentPage = 1
          this.$store.state.app.defectFilter.search = null
          this.$store.state.app.defectFilter.suite_id = []
        }
        setTimeout(() => {
          this.$store.state.app.defect.id = null
          this.back()
          this.loader = false
        }, 1000)
      } else {
        this.loader = false
      }
    },

    /** conformation for Defect Status Update */
    changeStatus(status) {
      const originalStatus = status
      status = status == 'not_an_issue'
        ? 'Not an issue'
        : status == 'resolve'
          ? 'Resolved'
          : status

      status = status[0].toUpperCase() + status.slice(1)
      this.$swal({
        title: `Are you sure you want to change the status to  ${status}?`,
        icon: 'info',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: status,
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (originalStatus == 'reopen' || originalStatus == 'not_an_issue') {
            this.$root.$emit('bv::toggle::modal', 'comment')
            this.newStatus = originalStatus
          } else {
            this.updateStatus(originalStatus)
          }
        }
      })
    },

    async updateStatus(data) {
      let comment
      let status
      if (typeof data === 'object') {
        comment = data.comment
        status = data.status
      } else {
        status = data
      }
      this.loader = true
      const input = {
        status,
      }

      if (status == 'not_an_issue' || status == 'reopen') {
        input.comment = comment
      }
      const response = await this.getHTTPPostResponse(
        `project/defect/status/${this.$store.state.app.defect.id}`,
        input,
        true,
      )

      if (response && response.status == 200) {
        setTimeout(() => {
          this.$store.state.app.defect.id = null
          if (this.$store.state.app.backAction) {
            this.back()
          }
          this.loader = false
        }, 1000)
      } else {
        this.loader = false
      }
    },

    clear() {
      this.$store.state.app.defect.code = null
      this.$store.state.app.defect.defect_title = null
      this.$store.state.app.defect.severity = null
      this.$store.state.app.defect.issue_discription = null
      this.$store.state.app.defect.step_to_reproduce = null
      this.$store.state.app.defect.attachment = []
      this.$store.state.app.defect.removedattachment = []
      this.$store.state.app.defect.assignee = null
      this.$store.state.app.defect.status = null
      this.$store.state.app.defect.defect_type = 'bug'
      this.$store.state.app.defect.test_suite = null
      this.previewImage = null

      clearInterval()
      this.file = []
      this.loader = false
      this.$refs.defectObserver.reset()
    },

    handleFileUpload($event) {
      this.file = $event.file
    },

    /* resolve back issue while fullscreen & privous url not have layout in query */
    getQueryParamFromUrl(url, key) {
      const urlSearchParams = new URLSearchParams(url.split('?')[1])
      return urlSearchParams.get(key)
    },
    getPreviousUrlQueryParam(key) {
      try {
        const previousUrl = document.referrer
        const layoutExists = previousUrl.includes('?layout=')

        if (!layoutExists) {
          return null
        }

        return this.getQueryParamFromUrl(previousUrl, key) || this.getQueryParamFromUrl(window.location.href, key)
      } catch (error) {
        return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
.defectBtn {
  padding-bottom: 10px !important;
}
</style>
