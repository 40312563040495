var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-project-form"},[_c('validation-observer',{ref:"prRequestObserver"},[_c('b-sidebar',{ref:"addEditRequest",staticClass:"sidebar-form",attrs:{"id":"addEditRequest","bg-variant":"white","right":"","backdrop":"","shadow":""},on:{"hidden":function($event){return _vm.clearData()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"form-button d-flex my-2"},[_c('button',{staticClass:"btn side-bar-btn",attrs:{"disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? "Update" : "Save")+" ")],1),_c('button',{staticClass:"btn side-bar-btn",on:{"click":function($event){return _vm.clearData()}}},[_vm._v(" Clear ")])])]},proxy:true}])},[_c('h3',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.id ? "Edit" : "Add")+" Request ")]),_c('div',{staticClass:"line user-underline"}),_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Title","label-for":"clientname"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required|max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Title","name":"clientname"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"PR Link","label-for":"PR Link"}},[_c('validation-provider',{attrs:{"name":"PR Link","rules":"required|url|https"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Platform","state":errors.length > 0 ? false : null,"placeholder":"Enter PR Link ","name":"PR Link"},model:{value:(_vm.platform),callback:function ($$v) {_vm.platform=$$v},expression:"platform"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"estimated_start_date"}},[_vm._v("Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configEstimatedStartDate,"placeholder":"Date","name":"estimated_start_date"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"estimated_start_date"}},[_vm._v("Time "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"select-time",style:(_vm.$store.state.app.isFullScreenModal ? 'text-align: start !important;' : null),attrs:{"id":"myInput","name":"start_time","type":"time","placeholder":"03:29 PM"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"description"},[_c('label',{attrs:{"for":"estimated_start_date"}},[_vm._v("Description")]),_c('quill-editor',{attrs:{"id":"issue_discription","name":"issue discription","options":_vm.descriptionEditorOption},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}},[_c('div',{attrs:{"slot":"toolbar","id":"toolbar1"},slot:"toolbar"},[_c('select',{staticClass:"ql-size"},[_c('option',{attrs:{"value":"small"}}),_c('option',{attrs:{"selected":""}}),_c('option',{attrs:{"value":"large"}}),_c('option',{attrs:{"value":"huge"}})]),_c('select',{staticClass:"ql-font"},[_c('option',{attrs:{"selected":"selected"}}),_c('option',{attrs:{"value":"serif"}}),_c('option',{attrs:{"value":"monospace"}})]),_c('button',{staticClass:"ql-bold"},[_vm._v(" Bold ")]),_c('button',{staticClass:"ql-italic"},[_vm._v(" Italic ")]),_c('button',{staticClass:"ql-underline"},[_vm._v(" Underline ")]),_c('button',{staticClass:"ql-clean"}),_c('select',{staticClass:"ql-align"},[_c('option',{attrs:{"selected":"selected"}}),_c('option',{attrs:{"value":"center"}}),_c('option',{attrs:{"value":"right"}}),_c('option',{attrs:{"value":"justify"}})]),_c('button',{staticClass:"ql-link",attrs:{"id":"ql-link"}},[_c('feather-icon',{attrs:{"icon":"Link2Icon","color":"white"}})],1),_c('button',{staticClass:"ql-list",attrs:{"value":"ordered"}}),_c('button',{staticClass:"ql-list",attrs:{"value":"bullet"}})])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }