var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary-form"},[_c('validation-observer',{ref:"memberRules"},[_c('b-sidebar',{staticClass:"sidebar-form sidebar-member addMemberPop add-member-sidebar",attrs:{"id":"addResourceTeamMember","bg-variant":"white","right":"","backdrop":"","shadow":""},on:{"hidden":_vm.sidebarToggle},model:{value:(_vm.sideBarOpen),callback:function ($$v) {_vm.sideBarOpen=$$v},expression:"sideBarOpen"}},[_c('div',{staticClass:"addMemberDeatils add-plan-member"},[_c('h3',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.id ? "Edit" : "Add")+" "+_vm._s(_vm.createType == "project_user" ? "Member" : "Plan")+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Member","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Member","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm select-user-nav",attrs:{"hide-details":"","name":"member","label":"name","options":_vm.$store.state.app.usersList,"reduce":function (user) { return user.id; },"placeholder":"Select Member","disabled":_vm.id ? true : false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6 col-12"},[(_vm.createType == 'project_user')?_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"hide-details":"","name":"role","label":"name","options":_vm.roles,"reduce":function (role) { return role.value; },"placeholder":"Select Role"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1544412982)})],1):_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Project","label-for":"project"}},[_c('validation-provider',{attrs:{"name":"project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"project","hide-details":"","name":"project","label":"project","options":_vm.projects,"get-option-label":function (project_id) { return project_id.name; },"placeholder":"Select project","disabled":_vm.id ? true : false},model:{value:(_vm.project_id),callback:function ($$v) {_vm.project_id=$$v},expression:"project_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])]),_c('div',{staticClass:"addAssInfo"},[_c('h3',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.id ? "Edit" : "Add")+" Assignment ")]),_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"mb-1"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,ref:"row",refInFor:true,staticClass:"d-flex flex-xl-nowrap flex-wrap mb-25",attrs:{"id":item.id}},[_c('div',{staticClass:"colDate"},[_c('b-form-group',{staticClass:"required-form-filed"},[_c('label',{attrs:{"for":"actual_start_date"}},[_vm._v("Assignment date")]),_c('validation-provider',{attrs:{"name":"Assignment start date","rules":{
                    required: true,
                    checkBothDatesSelected: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.items[index].configAssignmentStartDate,"name":"assignment_start_date","placeholder":"Select Month"},on:{"on-change":function($event){return _vm.setConfig(index, 'config')}},model:{value:(_vm.items[index].assignment_start_date),callback:function ($$v) {_vm.$set(_vm.items[index], "assignment_start_date", $$v)},expression:"items[index].assignment_start_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"20"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"colTimeplan"},[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Time Plan","label-for":"Time Plan"}},[_c('validation-provider',{attrs:{"name":"Time plan","rules":"min_value:0|max_value:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"id":"time_plan"}},[_c('b-form-input',{attrs:{"name":"time_plan","type":"number","placeholder":"Assign Hours"},on:{"input":function($event){return _vm.setConfig(index, 'setassignment')}},model:{value:(_vm.items[index].time_plan),callback:function ($$v) {_vm.$set(_vm.items[index], "time_plan", $$v)},expression:"items[index].time_plan"}}),_c('b-input-group-prepend',{staticClass:"inputTxt",attrs:{"is-text":""}},[_vm._v(" Hours ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"colPercentage"},[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Percentage","label-for":"assignment"}},[_c('validation-provider',{attrs:{"name":"Percentage","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"assignment","type":"number","placeholder":"Assign percentage","max":100},on:{"input":function($event){return _vm.setConfig(index)}},model:{value:(_vm.items[index].assignment),callback:function ($$v) {_vm.$set(_vm.items[index], "assignment", $$v)},expression:"items[index].assignment"}}),_c('b-input-group-prepend',{staticClass:"inputTxt",attrs:{"is-text":""}},[_vm._v(" % ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"colIsBillable"},[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"billable","label-for":"assignment"}},[_c('div',{staticClass:"align-items-center text-center"},[_c('div',{class:_vm.items[index].is_billable ? 'billable' : 'nonBillable'},[_c('span',{on:{"click":function($event){_vm.items[index].is_billable = !_vm.items[index].is_billable}}},[_vm._v("$")])])])])],1),_c('div',[_c('b-button',{staticClass:"deleteXicon",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteConfirmation(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)])}),0),_c('b-row',{staticClass:"addmoreUser"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon","size":"15"}}),_c('span',[_vm._v("Add New")])],1)],1)],1)]),_c('div',{staticClass:"form-button d-flex addmemBtns"},[_c('button',{staticClass:"btn side-bar-fillter",attrs:{"disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? "Edit" : "Add")+" "+_vm._s(_vm.createType == "project_user" ? "Member" : "Plan")+" ")],1),_c('button',{staticClass:"btn side-bar-clear",on:{"click":_vm.clearData}},[_vm._v(" Discard ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }