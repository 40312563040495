<template>
  <div>
    <div>
      <div class="test-form">
        <div class="scenario-steps view-steps">
          <b-row>
            <b-col cols="12">
              <b-skeleton class="assign"></b-skeleton>
              <b-skeleton class="suit-content1"></b-skeleton>
              <b-skeleton class="assign"></b-skeleton>
              <b-skeleton class="suit-content1"></b-skeleton>
              <b-skeleton class="assign"></b-skeleton>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="test-form mt-1">
        <div class="scenario-steps view-steps">
          <b-skeleton class="title"></b-skeleton>
          <b-row>
            <b-col cols="12">
                <div class="align-items-center d-flex">
                <b-skeleton class="tag"></b-skeleton>
              <div class="ml-2">
                <b-skeleton class="assign"></b-skeleton>
                <b-skeleton class="suit-content1"></b-skeleton>
              </div>
            </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TestPlanLoader",

}
</script>

<style>
.board-card-shimmer {
  width: 100% !important;
}
.title {
  width: 200px;
  height: 20px;
}
.tag {
  width: 40px;
  margin-right: 5px;
}
.assign {
  width: 100px;
}
.board-shimmer {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding: 0;
  max-width: 100%;
}
.suit-content1 {
  width: 100%;
  height: 20px;
}
.right-content {
  width: 100%;
  height: 40px;
}
.board-card-shimmer {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
</style>
