<template>
    <div style="float: left; width: 100%">      
      <team-list :project-dtl="projectDtl"></team-list>
    </div>
  </template>
  
  <script>
  import TeamList from "../projectsummary/TeamList.vue"

  
  export default {
    name: "Team",
    components: {
      TeamList,
   
    },
    props: {
      projectDtl: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        isshow: true,
        edit: false,
      }
    },
    mounted() {},
    methods: {
      editMethod() {
        this.isshow = false
      },
    },
  }
  </script>
  
  <style>
  </style>