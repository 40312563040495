<template>
  <div
    style="float: left; width: 100%"
    class="mb-5"
  >
    <project-detail :project-dtl="projectDtl"  :spend-hours-detail="spendHoursDetail"/>
    <timeline :project-dtl="projectDtl" />
  </div>
</template>

<script>
import Timeline from './TimelineInfo.vue'
import ProjectDetail from './ProjectDetail.vue'

export default {
  name: 'ProjectSummaryView',
  components: {
    Timeline,
    ProjectDetail,
  },
  props: {
    projectDtl: {
      type: Object,
      required: true,
      default: null,
    },
    spendHoursDetail: {
      type: Object,
      required: true,
      default: null,
    },
  },
}
</script>
