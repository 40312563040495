<template>
  <div class="summary-form">
    <validation-observer ref="memberRules">
      <b-sidebar id="addResourceTeamMember" v-model="sideBarOpen" bg-variant="white" right backdrop shadow
        class="sidebar-form sidebar-member addMemberPop add-member-sidebar" @hidden="sidebarToggle">
        <div class="addMemberDeatils add-plan-member">
          <h3 class="form-title">
            {{ id ? "Edit" : "Add" }}
            {{ createType == "project_user" ? "Member" : "Plan" }}
          </h3>
          <div class="row">
            <div class="col-md-6 col-12">
              <b-form-group label="Member" label-for="username" class="required-form-filed">
                <validation-provider #default="{ errors }" name="Member" rules="required">
                  <v-select v-model="user_id" hide-details name="member" label="name"
                    :options="$store.state.app.usersList" :reduce="(user) => user.id"
                    class="select-size-sm select-user-nav" placeholder="Select Member" :disabled="id ? true : false">
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <!--base on create type show role & project field-->
              <b-form-group v-if="createType == 'project_user'" label="Role" label-for="role"
                class="required-form-filed">
                <validation-provider #default="{ errors }" name="Role" rules="required">
                  <v-select v-model="role" hide-details name="role" label="name" :options="roles"
                    :reduce="(role) => role.value" class="select-size-lg" placeholder="Select Role" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group v-else label="Project" label-for="project" class="required-form-filed">
                <validation-provider #default="{ errors }" name="project" rules="required">
                  <v-select id="project" v-model="project_id" hide-details name="project" label="project"
                    :options="projects" :get-option-label="(project_id) => project_id.name" class="select-size-lg"
                    placeholder="Select project" :disabled="id ? true : false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="addAssInfo">
          <h3 class="form-title">
            {{ id ? "Edit" : "Add" }} Assignment
          </h3>

          <!-- :id="item.id" -->
          <div class="position-relative">
            <div class="mb-1">
              <div v-for="(item, index) in items" :id="item.id" :key="index" ref="row"
                class="d-flex flex-xl-nowrap flex-wrap mb-25">
                <div class="colDate">
                  <b-form-group class="required-form-filed">
                    <label for="actual_start_date">Assignment date</label>
                    <validation-provider #default="{ errors }" name="Assignment start date" :rules="{
                      required: true,
                      checkBothDatesSelected: true,
                    }">
                      <b-input-group class="input-group-merge">
                        <flat-pickr v-model="items[index].assignment_start_date" class="form-control"
                          :config="items[index].configAssignmentStartDate" name="assignment_start_date"
                          placeholder="Select Month" @on-change="setConfig(index, 'config')" />

                        <b-input-group-append is-text>
                          <feather-icon icon="CalendarIcon" size="20" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="colTimeplan">
                  <b-form-group label="Time Plan" label-for="Time Plan" class="required-form-filed">
                    <validation-provider #default="{ errors }" name="Time plan" rules="min_value:0|max_value:200">
                      <b-input-group id="time_plan" class="input-group-merge">
                        <b-form-input v-model="items[index].time_plan" name="time_plan" type="number"
                          placeholder="Assign Hours" @input="setConfig(index, 'setassignment')" />
                        <b-input-group-prepend is-text class="inputTxt">
                          Hours
                        </b-input-group-prepend>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="colPercentage">
                  <b-form-group label="Percentage" label-for="assignment" class="required-form-filed">
                    <validation-provider #default="{ errors }" name="Percentage"
                      rules="required|min_value:0|max_value:100">
                      <b-input-group class="input-group-merge">
                        <b-form-input id="assignment" v-model="items[index].assignment" type="number"
                          placeholder="Assign percentage" :max="100" @input="setConfig(index)" />

                        <b-input-group-prepend is-text class="inputTxt">
                          %
                        </b-input-group-prepend>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <!-- TODO: add is_billable field -->
                <!-- is billable start -->
                <div class="colIsBillable">
                  <b-form-group label="billable" label-for="assignment" class="required-form-filed">
                    <div class="align-items-center text-center">
                      <div :class="items[index].is_billable ? 'billable' : 'nonBillable'
                        ">
                        <span @click="
                          items[index].is_billable = !items[index].is_billable
                          ">$</span>
                      </div>
                    </div>
                  </b-form-group>
                  <!-- {{ items[index].is_billable }} -->
                </div>
                <!-- is billable end -->
                <div>
                  <b-button variant="danger" class="deleteXicon" @click="deleteConfirmation(index)">
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
            <b-row class="addmoreUser">
              <b-button variant="primary" @click="repeateAgain">
                <feather-icon icon="PlusIcon" class="mr-25" size="15" />
                <span>Add New</span>
              </b-button>
            </b-row>
          </div>
        </div>
        <div class="form-button d-flex addmemBtns">
          <button class="btn side-bar-fillter" :disabled="btnLoader" @click.prevent="validationForm">
            <b-spinner v-if="btnLoader" small class="spinner" />
            {{ id ? "Edit" : "Add" }}
            {{ createType == "project_user" ? "Member" : "Plan" }}
          </button>
          <button class="btn side-bar-clear" @click="clearData">
            Discard
          </button>
        </div>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import vSelect from 'vue-select'
import { eventBus } from '@/main'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

extend('checkBothDatesSelected', {
  validate(value) {
    // Custom validation rule logic
    //
    const dateArr = value.split(' to ')
    return dateArr.length === 2
  },
  message: 'Please select both dates.',
})

export default {
  name: 'AddMember',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  props: {
    teams: {
      type: Array,
      required: true,
      default: () => []
    },
    createType: {
      type: String,
      required: true,
      default: null
    },
  },
  data() {
    return {
      id: null,
      projects: [],
      user_id: null,
      role: null,

      assignment: null,
      assignment_start_date: null,
      assignment_end_date: null,
      time_plan: null,
      btnLoader: false,
      project_id: null,
      project_user_id: null,
      users: [],
      plans: [],
      roles: [
        { name: 'Project Manager', value: 'project_owner' },
        { name: 'BA and Co-ordinator', value: 'co_ordinator' },
        { name: 'Member', value: 'member' },
      ],
      configAssignmentStartDate: {
        minDate: moment().startOf('month').format('YYYY-MM-DD'),
        maxDate: null,
        dateFormat: 'Y-m-d',
        mode: 'range',
      },
      configAssignmentEndDate: {
        minDate: new Date(),
        maxDate: null,
        dateFormat: 'Y-m-d',
        mode: 'range',
      },

      items: [
        {
          id: null,
          assignment: null,
          assignment_start_date: null,
          time_plan: null,
          configAssignmentStartDate: {
            mode: 'range',

            /**  Todo Change :-  Revome min & max date  As requirement by Mayur Sir */
            // minDate: moment().startOf("month").format("YYYY-MM-DD"),
            // maxDate: moment().endOf("month").format("YYYY-MM-DD"),
            minDate: null,
            maxDate: null,
            dateFormat: 'Y-m-d',
            disable: [],
            projectUserId: null,
          },
          is_billable: true,
        },
      ],
      sideBarOpen: false,
      endDate: null,
      disabledDates: [],
    }
  },
  watch: {

    user_id(nv) {
      if (nv && !this.id) {
        this.$refs.memberRules.reset()

        /**  To Do Change 31-07-23:- on users change clear plans As Requiremenet from Mayur Sir */

        this.clearItems()
        this.disabledDates = []
        if (this.createType != 'project_user' && this.project_id) {
          this.getUserPlan()
        }
        if (this.createType == 'project_user') {
          this.getProjectUserDetail()
        }
      }
    },
    project_id(nv) {
      this.disabledDates = []

      if (nv && this.user_id && !this.id) {
        this.getUserPlan()
      }
    },
    sideBarOpen(value) {
      if (value) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },
    // items(nv) {
    //    this.updateDisableField();
    // },
  },
  mounted() {
    if (this.createType == 'plan') {
      this.projectList()
    }
    if (this.$store.state.app.usersList && this.$store.state.app.usersList.length === 0) {
      this.userList()
    }
    eventBus.$on('edit-plannedHours', data => {
      if (data) {
        data.type = 'plan'
        data.project_id = data && data.project && data.project.id ? data.project.id : null
        this.id = data.id
        this.user_id = data.user_id
        this.project_id = data && data.project ? data.project : null

        this.assignment_start_date = `${data.year + data.month}01` + `to${data.year}${data.month}31`
        this.year = data.year
        // this.time_plan = data.planned_hours;
        // this.setConfig("config");

        const start_date = data.expected_assignment_start_date
          ? data.expected_assignment_start_date
          : moment(`01-${data.month}-${data.year}`, 'DD-MM-YYYY')
            .startOf('month')
            .format('YYYY-MM-DD')
        const end_date = data.expected_assignment_end_date
          ? data.expected_assignment_end_date
          : moment(`01-${data.month}-${data.year}`, 'DD-MM-YYYY')
            .endOf('month')
            .format('YYYY-MM-DD')

        this.items = [
          {
            id: data.id,
            assignment: data.assignment,
            assignment_start_date: `${start_date} to ${end_date}`,
            time_plan: data.planned_hours,
            configAssignmentStartDate: {
              mode: 'range',
              minDate: null,
              maxDate: null,
              dateFormat: 'Y-m-d',
              disable: this.disabledDates,
            },
            is_billable: data.is_billable,
          },
        ]
        this.checkExistingDateData(
          data,
          data.expected_assignment_start_date,
          data.expected_assignment_end_date,
        )
      }
    })

    eventBus.$on('edit-member', data => {
      if (data) {
        data.type = 'projectUser'
        this.id = data.project_id
        this.user_id = data.user_id
        this.project_user_id = data.id
        this.role = data.access_rights
        this.items = [
          {
            id: data.id,
            assignment: data.assignment,
            assignment_start_date:
              `${data.expected_assignment_start_date
              } to ${data.expected_assignment_end_date}`,
            time_plan: data.planned_hours,
            configAssignmentStartDate: {
              mode: 'range',
              minDate: null,
              maxDate: null,
              dateFormat: 'Y-m-d',
              disable: this.disabledDates,
            },
            is_billable: data.is_billable,
          },
        ]
        this.checkExistingDateData(
          data,
          data.expected_assignment_start_date,
          data.expected_assignment_end_date,
        )
      }
    })
  },
  destroyed() {
    eventBus.$off('edit-plannedHours')
    eventBus.$off('edit-member')
  },
  methods: {
    removeItem(index) {
      // this.updateDateRange(index)
      this.disabledDates.splice(index, 1)
      this.items.splice(index, 1)
      // this.resetDisbaleDates();
    },

    resetDisbaleDates() {
      this.items.forEach((element, index) => {
        const { disabledDates } = this
        element.configAssignmentStartDate.disable = disabledDates.splice(
          index,
          1,
        )
      })
    },

    repeateAgain() {
      this.$refs.memberRules.validate().then(success => {
        if (success) {
          // const minDate = moment(this.endDate, 'YYYY-MM-DD')
          //   .add(1, 'days')
          //   .format('Y-MM-DD')
          this.items.push({
            assignment: null,
            id: null,
            assignment_start_date: null,
            time_plan: null,
            configAssignmentStartDate: {
              mode: 'range',
              // minDate: minDate,
              maxDate: null,
              dateFormat: 'Y-m-d',
              disable: this.disabledDates,
            },
            is_billable: true,
          })
          this.items.sort(this.compareObjectsByStartDate)
        }
      })
    },

    sidebarToggle() {
      this.id = null
      this.clearData()
    },
    /**
     * Clear form details
     */
    clearData() {
      this.project_id = null

      this.user_id = null
      this.role = null

      this.name = null
      this.clearItems()
      this.$refs.memberRules.reset()
      this.disabledDates = []
      document.body.classList.remove('overflow-hidden')
    },

    /** * clear items */
    clearItems() {
      this.items = [
        {
          id: null,
          assignment: null,
          assignment_start_date: null,
          time_plan: null,
          configAssignmentStartDate: {
            mode: 'range',
            dateFormat: 'Y-m-d',
          },
          is_billable: true,
        },
      ]
      this.$refs.memberRules.reset()
    },
    /* project list */
    async projectList() {
      this.projects = []
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'project/time-log',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        data.projects.forEach(element => {
          if (element.work_type == 'productive') {
            this.projects.push(element)
          }
        })
      }
    },

    /*  set config of time plan & assignment base on date or other condition */
    setConfig(index, action) {
      /*   date separate  */
      const dateArr = this.items[index].assignment_start_date
        ? this.items[index].assignment_start_date.split(' to ')
        : []

      if (dateArr.length == 2) {
        const startDate = dateArr[0]
        const endDate = dateArr[1] ? dateArr[1] : dateArr[0]
        /*  set min & max date based on startDate */
        const minDate = moment(startDate).startOf('month').format('YYYY-MM-DD')
        const maxdate = moment(startDate).endOf('month').format('YYYY-MM-DD')

        if (maxdate < moment(endDate).format('YYYY-MM-DD')) {
          this.items[index].assignment_start_date = `${startDate}to${maxdate}`
        }
        let calcBusinessDays = 1 + moment(endDate).diff(moment(startDate), 'days') // selected dates
        // let monthdays =1 + moment(maxdate).diff(moment(minDate), "days"); //total month days
        calcBusinessDays = calcBusinessDays > 30 ? 30 : calcBusinessDays
        const monthdays = moment(startDate).endOf('month').format('MM') == '02'
          ? 1 + moment(maxdate).diff(moment(minDate), 'days')
          : 30

        switch (action) {
          case 'config':

            /**  To Do Change 31-07-23:- on users change clear plans As Requiremenet from Mayur Sir */

            // this.$set(
            //   this.items[index].configAssignmentStartDate,
            //   "maxDate",
            //   maxdate
            // );
            // this.$set(
            //   this.items[index].configAssignmentStartDate,
            //   "minDate",
            //   minDate
            // );

            /*  check assignment value issue */
            this.items[index].assignment = this.items[index].assignment
              ? this.items[index].assignment
              : '100'

            /*  set or update hours as per assignment  >> already value >> default set */
            this.items[index].time_plan = this.items[index].assignment
              ? (
                ((this.items[index].assignment * 160) / (100 * monthdays))
                * calcBusinessDays
              ).toFixed(2)
              : this.items[index].time_plan
                ? this.items[index].time_plan
                : ((160 / (monthdays + 1)) * calcBusinessDays).toFixed(2)

            /* set disable date range */
            // this.$set(
            //   this.items[index].configAssignmentStartDate,
            //   "disable",
            //   this.disabledDates
            // );
            // this.updateDateRange(index)
            // this.disabledDates.push({
            //   from: startDate,
            //   to: endDate,
            // });
            break
          case 'setassignment':
            /*  consider  hours = 20 , total = 160 , monthday=30 , calcday = 20   */

            // (hours*100*monthdays) / (total * calcdays)
            //  20 * 100 * 30 =60000 ,  160 * 20 =3200 , 60000 /3200 = 18.75

            this.items[index].assignment = (
              (this.items[index].time_plan * 100 * monthdays)
              / (160 * calcBusinessDays)
            ).toFixed(2)
            break

          default:
            /* Consider

                   assignment = 40 %
                   monthdays = 30
                   start date = 09 / 11 / 22 to 30 /11 / 22 so calcBusinessDays=21

                   total month  hours as per assignment =( 40 * 160  / 100)
                   per day hours = total hours / monthdays
                   time_plan =  per_day_hour * calcBusinessDays

                 */

            this.items[index].time_plan = (
              ((this.items[index].assignment * 160) / (100 * monthdays))
              * calcBusinessDays
            ).toFixed(2)
        }
        this.items[index].time_plan = this.items[index].time_plan > 160 ? 160 : this.items[index].time_plan
        this.items[index].assignment = this.items[index].assignment > 100
          ? 100
          : this.items[index].assignment
      }
      this.items.sort(this.compareObjectsByStartDate)
    },

    validationForm() {
      this.$refs.memberRules.validate().then(success => {
        if (success) {
          if (this.items && this.items.length) {
            this.btnLoader = true

            /* set input object pass */

            this.items.forEach(element => {
              if (element.assignment_start_date) {
                const dateArr = element.assignment_start_date.split(' to ')
                element.expected_assignment_start_date = dateArr[0]
                element.expected_assignment_end_date = dateArr[1]
                  ? dateArr[1]
                  : moment(dateArr[0]).format('YYYY-MM-DD')
                element.month = moment(dateArr[0]).format('MM')
                element.year = moment(dateArr[0]).format('YYYY')
                element.project_id = this.project_id
                  ? this.project_id.id
                  : this.$route.params.id3
                element.user_id = this.user_id
                // project_user_id: this.project_user_id,
                element.access_rights = this.role ? this.role : this.user_id == this.userInfo.id ? 'project_owner' : 'member'
                element.createType = this.createType
                element.planned_hours = element.time_plan
              }
            })

            const input = {
              plan: this.items,
            }

            this.addProjectUserPlans(input)

            //
          }
        }
      })
    },

    /* member add in project */
    async addProjectUserPlans(input) {
      const response = await this.getHTTPPostResponse(
        'project-resource/create-plan-user',
        input,
        true
      )

      if (response && response.status == 200) {
        setTimeout(() => {
          if (this.createType == 'project_user') {
            eventBus.$emit('reloadProjectDtl', true)
          } else {
            this.$emit('plannedUserList', true)
          }
          this.btnLoader = false
          this.clearData()
          this.sideBarOpen = false
        }, 500)
      } else {
        this.btnLoader = false
      }
    },

    /**
     * confirmation to delete user
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure you want to delete this Record?',
        icon: 'info',
        html: '<p>You won’t be able to revert this!</p>',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeItem(id)
        }
      })
    },

    /**
     * get  user plan detail existed plan
     */
    async getUserPlan() {
      const input = {
        project_id: this.project_id
          ? this.project_id.id
          : this.$route.params.id3,
        user_id: this.user_id,
      }
      const response = await this.getHTTPPostResponse(
        'project-resource/get-plan',
        input,
        false,
      )
      if (response && response.status == 200) {
        const item = !!response.data.resourcePlan

        if (item) {
          this.storeItems(response.data.resourcePlan)
        }
      }
    },

    /**
     * get project user detail existed plan
     */
    async getProjectUserDetail() {
      const input = {
        project_id: this.project_id
          ? this.project_id.id
          : this.$route.params.id3,
        user_id: this.user_id,
      }
      const response = await this.getHTTPPostResponse(
        'project-resource/get-project-user',
        input,
        false,
      )
      if (response && response.status == 200) {
        const item = !!response.data.projectUser

        if (item) {
          this.storeItems(response.data.projectUser)
          this.role = response.data.projectUser && response.data.projectUser.length
            ? response.data.projectUser[0].access_rights
            : null
        }
      }
    },

    /**
     * common Function for fetched plan &  members to store in items variable
     */
    storeItems(data) {
      const items = []
      data.forEach(element => {
        this.assignment_start_date = `${element.year
          + element.month
          }01`
          + `to${element.year
          }${element.month
          }31`
        this.year = element.year
        const start_date = element.expected_assignment_start_date
          ? element.expected_assignment_start_date
          : moment(`01-${element.month}-${element.year}`, 'DD-MM-YYYY')
            .startOf('month')
            .format('YYYY-MM-DD')
        const end_date = element.expected_assignment_end_date
          ? element.expected_assignment_end_date
          : moment(`01-${element.month}-${element.year}`, 'DD-MM-YYYY')
            .endOf('month')
            .format('YYYY-MM-DD')

        const obj = {
          id: element.id,
          assignment: element.assignment,
          assignment_start_date: `${start_date} to ${end_date}`,
          time_plan:
            this.createType == 'project_user'
              ? element.time_plan
              : element.planned_hours,
          configAssignmentStartDate: {
            mode: 'range',
            dateFormat: 'Y-m-d',
            disable: this.disabledDates,
          },
          is_billable: element.is_billable,
        }
        // setTimeout(() => {
        //   this.disabledDates.push({
        //     from: start_date,
        //     to: end_date,
        //   });
        // }, 100);
        items.push(obj)
      })
      if (items && items.length) {
        items.sort(this.compareObjectsByStartDate)

        this.items = items
      }
    },
    /* update date Range */
    // updateDateRange(index) {
    // this.disabledDates = this.disabledDates.filter(
    //   (item, Dindex) => Dindex !== index
    // );
    // },

    /** Check Existing date */
    // checkExistingDateData(data, start_date, end_date)
    async checkExistingDateData(data) {
      this.btnLoader = true
      const input = {
        project_id: data.project_id,
        user_id: data.user_id,
        type: data.type,
      }
      const response = await this.getHTTPPostResponse(
        'project-resource/check-date',
        input,
        false,
      )
      if (response && response.status == 200) {
        this.disabledDates = response.data.dates
        // const disable = this.disabledDates.filter(
        //   obj => obj.from != start_date,
        // )
        // this.items[0].configAssignmentStartDate.disable = disable;
      }
      this.btnLoader = false
    },
    compareObjectsByStartDate(a, b) {
      // Extracting start dates from the assignment_start_date property
      const startDateA = new Date(a.assignment_start_date.split(' to ')[0])
      const startDateB = new Date(b.assignment_start_date.split(' to ')[0])

      // Comparing start dates
      return startDateA - startDateB
    },

    // updateDisableField() {
    //   this.items.forEach((element, index) => {});
    // },
  },
}
</script>

<style>
@import "../../../assets/scss/component-css/projectsummary.css";
@import "../../../assets/scss/component-css/report.css";
</style>
