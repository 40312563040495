<template>
  <div style="float: left; width: 100%; padding-bottom: 50px">
    <div class="total-milestone">
      <b-row>
        <b-col
          v-if="addEditRights"
          cols="3"
          md="6"
          lg="8"
        >
          <button
            v-b-toggle.addMilestone
            class="milestone-add"
          >
            Add Milestone
          </button>
        </b-col>
        <b-col
          cols="9"
          md="6"
          lg="4"
          class="d-flex justify-content-end search-section"
        >
          <div class="w-100">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="debouncedSearch"
                placeholder="Search"
              />

              <b-input-group-append is-text>
                <feather-icon
                  v-if="debouncedSearch"
                  icon="XIcon"
                  class="xicon-cancel"
                  @click="debouncedSearch=null,headersearch = null"
                />
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="load_mileston">
      <accordion-shimmer class="my-1" />
      <accordion-shimmer class="my-1" />
      <accordion-shimmer class="my-1" />
    </div>
    <div
      v-else-if="items.length"
      class="mt-1"
    >
      <milestone
        v-for="(item, index) in items"
        :key="index"
        :data="item"
        :index="items.indexOf(item)"
        :rights="addEditRights"
        @updatemilestone="milestoneList()"
      />
      <div />
    </div>
    <img
      v-else
      src="../../../assets/images/nodatafound/nodata.svg"
      alt="no-data"
      class="no-data-img"
    >
    <!-- <div
      v-if="
        userdata(this.data, userInfo.id) === 'project_owner' ||
        userdata(this.data, userInfo.id) === 'co_ordinator' ||
        userInfo.role === 'SA'
      "
    >
      <button
        class="milestone-add"
        v-b-toggle.addMilestone
        v-if="milestoneHide !== 1"
      >
        Add Milestone
      </button>
    </div> -->
    <!-- add milestone form -->
    <add-milestone
      fromtitle="Add Milestone"
      :milestone-count="items.length"
      class="mb-2"
    />
  </div>
  <!-- <img
  src="../../../assets/images/nodatafound/nodata.svg"
  alt="no-data"
  class="no-data-img"
  v-else
/> -->

</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  VBToggle,
} from 'bootstrap-vue'
import { eventBus } from '@/main'
import debounce from 'lodash/debounce'
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'
import AddMilestone from './AddMilestone.vue'
import Milestone from './Milestone.vue'
import AccordionShimmer from '../../shimmer/accordion.vue'

export default {
  name: 'ProjectMasterPlan',
  components: {
    BRow,
    BCol,
    BFormInput,
    FeatherIcon,
    BInputGroup,
    AddMilestone,
    Milestone,
    AccordionShimmer,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    projectDtl: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      milestoneHide: null,
      rights: null,
      page: null,
      per_page: null,
      sort_field: null,
      sort_order: null,
      is_active: null,
      search: null,
      items: [],
      data: [],
      totalCount: null,
      headersearch: null,
      load_mileston: false,
      milestoneSearch: false,
    }
  },
  computed: {
    addEditRights() {
      if (this.userInfo && this.userInfo.role == 'SA') {
        return true
      } if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const user = this.projectDtl.project_user.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights == 'project_owner'
            || user.access_rights == 'co_ordinator')
        ) {
          return true
        }
        // access_rights = project_owner, co_ordinator, member
      }
      return false
    },
    debouncedSearch: {
      get() {
        return this.headersearch
      },
      set: debounce(function (value) {
        this.headersearch = value
      }, 750),
    },
  },
  watch: {
    headersearch(value) {
      this.milestoneSearch = true
      if ((value && value.length >= 3) || !value) {
        this.search = value
        this.milestoneList()
        this.milestoneHide = 1
      } else if (value.length == 0) {
        this.search = value
        this.milestoneHide = 0
        this.milestoneList()
      }
    },
  },
  mounted() {
    this.load_mileston = true
    this.milestoneList()
    /** */
    this.data = this.$store.state.app.projectDetail

    eventBus.$on('milestoneList', data => {
      if (data) {
        this.milestoneList()
      }
    })
  },
  methods: {
    async milestoneList() {
      this.load_mileston = true
      const input = {
        page: this.currentPage,
        per_page: this.perPage,
        sort_field: 'status',
        sort_order: 'desc',
        is_active: this.is_active,
        search: this.headersearch,
        project_id: this.$route.params.id3,
      }

      const response = await this.getHTTPPostResponse(
        'project/plan/lists',
        input,
        false,
      )

      if (response && response.data) {
        const { data } = response
        data.project_plans.sort((a, b) => {
          const num1 = a.title.split('-')
          const num2 = b.title.split('-')
          return num1
            && num1.length
            && num1[1]
            && num2
            && num2.length
            && num2[1]
            ? num1[1] - num2[1]
            : a.title - b.title
        })
        this.items = data.project_plans

        this.totalCount = data.count
        this.load_mileston = false
      }
      this.loading = false
    },
    /*  get project detail */
    // async getprojectdetail(id) {
    //   let response = await this.getHTTPPostResponse(
    //     `project/view/${id}`,
    //     {},
    //     false
    //   );

    //   if (response) {
    //     let data = response.data.project;
    //     this.data = data;

    //   }
    // },

    userdata(data, user_id) {
      let a = null
      if (data && data.project_user && data.project_user.length) {
        const b = data.project_user
        for (let i = 0; i < b.length; i++) {
          if (data.project_user[i].users.id === user_id) {
            a = data.project_user[i].access_rights
            this.rights = a
          }
        }
      }
      return a
    },
  },
}
</script>

<style>
@import "../../../assets/scss/component-css/masterplan.css";
</style>
