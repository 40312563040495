<template>
  <div v-if="!loader">
    <div>
      <!--filter start-->
    </div>
    <div>
      <div class="test-form mt-1">
        <b-row>
          <!-- first name -->
          <b-col cols="3" class="mb-1">
            <b-form-group label="ID" label-for="v-id" class="mb-0">
              <p>{{ code }}</p>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Title" label-for="v-title" class="mb-0">
              <p>{{ title }}</p>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="project-select">
            <b-form-group label="Test Severity" label-for="TestSeverity" class="mb-0">
              <p>{{ testSeverity | capitalize }}</p>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="project-select">
            <b-form-group label="Application Type" label-for="ApplicationType" class="mb-0">
              <p>{{ applicationType }}</p>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="project-select">
            <b-form-group label="Test Suite" label-for="TestSuite" class="mb-0">
              <p>{{ testSuite() }}</p>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="project-select">
            <b-form-group v-if="lastTestRun" class="mb-0">
              <label> Last Tested Date </label>
              <p>{{ DDMMYYYYFormat(lastTestRun) }}</p>
            </b-form-group>
            <b-form-group v-else class="mb-0">
              <label> Not Tested Yet </label>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <h4>No contact added yet</h4>-->
      </div>
      <div class="test-form mt-1">
        <b-row>
          <b-col cols="12">
            <div class="description view-steps">
              <label for="textarea-default">Description</label>
              <div v-if="description && checkDesription(description)" v-html="description" />
              <h4 v-else>
                No content added yet
              </h4>
            </div>
          </b-col>
        </b-row>
        <!-- <h4>No contact added yet</h4> -->
      </div>
      <div class="test-form mt-1">
        <b-row>
          <b-col>
            <div>
              <label for="textarea-default">Attachment</label>
              <!-- <Attechment ref="attechmentFiles"/> -->

              <div v-if="attachment.length" class="mb-1 download-content" style="">
                <div class="download-files">
                  <div v-for="(image, key) in attachment" :key="key" class="download-link p-50" role="button"
                    @click="openFileInNewTab(image.url)">
                    <!-- <a :href="image.url | attachmentURL" target="_blank"> -->
                    {{ image.name }}
                    <!-- </a> -->
                    <feather-icon icon="EyeIcon" color="white" />
                  </div>
                </div>
              </div>

              <h4 v-else>
                No content added yet
              </h4>
            </div>
          </b-col>
        </b-row>
        <!-- <h4>No contact added yet</h4> -->
      </div>
      <div class="test-form mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Expected Result" label-for="v-expected-result" class="mb-0">
              <p v-if="expectResult" v-html="expectResult" />
              <h4 v-else>
                No content added yet
              </h4>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <h4>No contact added yet</h4> -->
      </div>

      <div class="test-form mt-1">
        <div class="scenario-steps view-steps">
          <b-row>
            <b-col cols="12">
              <div>
                <label for="textarea-default">Scenario Steps</label>
                <div v-if="scenarioSteps && checkDesription(scenarioSteps)" v-html="scenarioSteps" />
                <h4 v-else>
                  No content added yet
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row />
        </div>
        <!-- <h4>No contact added yet</h4> -->
      </div>
    </div>
    <div v-if="
      $store.state.app.testCaseIdOfRun &&
      $store.state.app.testCaseIdOfRun.status &&
      $store.state.app.testCaseIdOfRun.status == 'pending' &&
      $store.state.app.testCaseIdOfRun.start_time &&
      !$store.state.app.testCaseIdOfRun.end_time
    " class="mt-2 position-relative zindex-4">
      <div class="d-flex my-2 align-items-center" role="button" @click="reDirectTab()">
        <div class="fill-plus-icon">
          <feather-icon icon="PlusIcon" width="18" height="18" color="#FFFFFF" />
        </div>

        <p class="add-step ml-50">
          Add Defect [{{ $store.state.app.testCaseIdOfRun.defects_count }}]
        </p>
      </div>
      <b-button class="mr-1 resolve" @click="changeStatus('passed')">
        Passed
      </b-button>

      <b-button class="mr-1 close-status" @click="
        $store.state.app.testCaseIdOfRun.defects_count
          ? changeStatus('defect')
          : checkDefectCount('defect')
        ">
        Failed
      </b-button>
    </div>
    <div v-if="
      $store.state.app.testCaseIdOfRun &&
      $store.state.app.testCaseIdOfRun.status &&
      $store.state.app.testCaseIdOfRun.status == 'pending' &&
      !$store.state.app.testCaseIdOfRun.start_time &&
      !$store.state.app.testCaseIdOfRun.end_time
    " class="mt-2 position-relative zindex-4">
      <b-button class="mr-1 close-status" @click="updateTime()">
        Start
      </b-button>
    </div>
  </div>
  <ViewSuitcaseVue v-else />
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import moment from 'moment'
import ViewSuitcaseVue from './Loader/ViewSuitcase.vue'

export default {
  name: 'ViewTestCase',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    ViewSuitcaseVue,
  },
  // props: [
  //   'code',
  //   'title',
  //   'applicationType',
  //   'testSeverity',
  //   'description',
  //   'expectResult',
  //   'scenarioSteps',
  //   'attachment',
  //   'lastTestRun',
  //   'loader',
  //   'suiteId',
  // ],
  props: {
    code: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    applicationType: {
      type: Array,
      required: true,
      default: () =>[]
    },
    testSeverity: {
      type: String,
      required: true,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    expectResult: {
      type: String,
      required: false,
      default: null
    },
    scenarioSteps: {
      type: String,
      required: false,
      default: null
    },
    attachment: {
      type: String,
      required: false,
      default: null
    },
    lastTestRun: {
      type: String,
      required: false,
      default: null
    },
    loader: {
      type: Boolean,
      required: true,
      default: false
    },
    suiteId: {
      type: String,
      required: false,
      default: null
    }
  },

  destroyed() {
    this.$store.commit('app/UPDATE_TEST_CASE_ID_OF_RUN', null)
  },

  methods: {
    back() {
      const { id } = this.$route.params
      const { id2 } = this.$route.params

      if (this.$route.params.id7) {
        this.$router.push({
          name: 'projectTask',
          params: {
            id,
            id2,
            id3: this.$route.params.id3,
            id4: this.$route.params.id4,
            id5: this.$route.params.id5,
            id6: this.$route.params.id6,
          },
          query: this.$route.query,
        }).catch(err => {
          // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
        this.$store.state.app.pageShow = 'testCasesTableVue'
      } else {
        this.$store.state.app.pageShow = this.$store.state.app.backAction
          || (this.$route.params.id3 == 'test-case' ? 'test-suite' : null)
        this.$router.push({
          name: 'projectTask',
          params: {
            id,
            id2,
            id3: 'test-suite',
            id4: 'create',
            id5: null,
            id6: null,
          },
          query: this.$route.query,
        }).catch(err => {
          // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      }
    },
    checkDesription(value) {
      const str = value.replace(/<p>\s*<\/p>|<p><br><\/p>|<br>/g, '')
      return str.length > 0
    },
    DDMMYYYYFormat(value) {
      return value ? moment(value).format('DD-MM-YYYY HH:mm') : null
    },
    /** conformation for Test Case Status Update */
    changeStatus(status) {
      let label = status[0].toUpperCase() + status.slice(1)
      label = label == 'Defect' ? 'Failed' : label
      this.$swal({
        title: `Are you sure you want to change the status to  ${label}?`,
        icon: 'info',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: label,
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          status = status.toLowerCase()
          this.updateStatus(status)
        }
      })
    },
    /**
     * check Case defect count
     */
    checkDefectCount() {
      this.$swal({
        title: 'They are no any defect created, please create atleast one defect!',
        icon: 'info',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Add defect',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.reDirectTab()
        }
      })
    },
    /**
     * Test Case Status Update
     */
    async updateStatus(status) {
      const data = this.$store.state.app.testCaseIdOfRun
      const { id } = data
      const input = {
        plan_id: data?.plan_id,
        project_id: data?.project_id,
        title: data?.title,
        status,
        test_case_id: data?.test_case_id,
        test_run_id: data?.test_run_id,
        start_time:
          data && data.start_time
            ? moment(data.start_time, 'YYYY-MM-DD HH:mm:ss').format(
              'YYYY-MM-DD HH:mm:ss',
            )
            : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        end_time: data.end_time
          ? data.end_time
          : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      }
      const response = await this.getHTTPPutResponse(
        `project/test-run/update-test-run-case/${id}`,
        input,
        false,
      )
      if (response && response.status == 200) {
        this.$store.state.app.pageShow = this.$store.state.app.backAction
        this.$store.state.app.backAction = null
        this.back()
      }
    },

    async updateTime() {
      const data = this.$store.state.app.testCaseIdOfRun
      const { id } = data
      const input = {
        plan_id: data.plan_id,
        project_id: data.project_id,
        title: data.title,
        status: this.status?.value,
        test_case_id: data.test_case_id,
        test_run_id: data.test_run_id,
        start_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        end_time: null,
      }
      const response = await this.getHTTPPutResponse(
        `project/test-run/update-test-run-case/${id}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        this.$store.state.app.testCaseIdOfRun.start_time = input.start_time
        // this.$store.state.app.pageShow = this.$store.state.app.backAction;
        // this.back();
        // this.$store.state.app.backAction = null;
      }
    },

    reDirectTab() {
      this.$store.state.app.testActiveName = 'create'
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: 'defects',
          id5: 'create',
          id6: null,
          id7: null,
        },
        query: {
          test_case: this.$route.params.id7, suiteId: this.suiteId, layout: this.currentLayout, pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

    testSuite() {
      const id = this.$store.state.app.suiteId

      const element = this.$store.state.app.suiteListWithoutTestCase.find(
        element => element.id == id,
      )
      return element && element.suite_name ? element.suite_name : null
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";

.dropzone {
  background-color: #fff !important;
  border: 1px dashed;
  padding: 40px 20px !important;
}

.more {
  display: inline-block;
  margin: 16px;
  border: 3px dashed lightgray;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  color: lightgray;
  border-radius: 8px;
  font-size: 60px;
  text-align: center;
  line-height: 200px;
  pointer-events: none;
}

.fill-plus-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #5e5873;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-step {
  color: #5e5873;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
</style>
