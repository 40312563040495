<template>
  <div>
    <div class="facebook-card">
      <skeleton-loader-vue
        type="ract"
        :height="60"
        animation="wave"
        class="shimmer-accordion"
      />
    </div>
  </div>
</template>

<script>
import SkeletonLoaderVue from "skeleton-loader-vue"

export default {
  name: "AccordionShimmer",
  components: {
    SkeletonLoaderVue,
  },
}
</script>

<style>
.shimmer-accordion {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
</style>