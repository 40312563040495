<template>
  <div>
    <div>
      <div class="card-filter-section">
        <div
          class="d-flex align-items-center flex-wrap justify-content-between flex-wrap"
        >
          <div
            class="d-flex align-items-center justify-content-start flex-wrap"
          >
            <p class="filter-title">
              Defects [{{ totalCount }}]
            </p>
          </div>

          <div class="d-flex align-items-center flex-wrap">
            <div class="w-lg-100">
              <custom-dropdown
                v-model="suite_id"
                :options="suiteListOption"
                label="Suites"
                icon="EditIcon"
                multiple
              />
            </div>
            <div class="w-lg-100">
              <custom-dropdown
                v-model="assigneeUser"
                :options="projectUsers"
                label="Assignee"
                icon="EditIcon"
                multiple
              />
            </div>
            <div class="">
              <custom-dropdown
                v-model="status"
                :options="statusOptions"
                label="status"
                icon="EditIcon"
                multiple
              />
            </div>
            <div class="w-lg-100">
              <custom-dropdown
                v-model="severity"
                :options="defectSeverityOptions"
                label="Severity"
                icon="EditIcon"
                multiple
              />
            </div>
            <div class="w-lg-100">
              <custom-dropdown
                v-model="defectType"
                :options="defectTypeOptions"
                label="Type"
                icon="EditIcon"
                multiple
                :search-box="false"
              />
            </div>
            <OptionDropdown v-model="perPage" />
            <b-input-group
              class="input-group-merge search-project ml-2"
              style="min-width: 250px; max-width: 250px; width: 250px"
            >
              <b-form-input
                v-model="debouncedSearch"
                placeholder="Search"
              />

              <b-input-group-append is-text>
                <feather-icon
                  v-if="debouncedSearch"
                  icon="XIcon"
                  @click="debouncedSearch = null,search=null"
                />
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>

            <div class="ml-lg-1">
              <button
                class="apply-filter filterRedesign mr-1"
                @click="applyFilter()"
              >
                Apply Filter
              </button>
              <button
                class="apply-filter-clear filterRedesign mr-1"
                @click="clearFilter()"
              >
                Clear
              </button>
              <button
                class="create-filter filterRedesign mr-1"
                @click="openDefect(null, 'Create')"
              >
                Create New Defect
              </button>
              <button
                class="apply-filter-clear filterRedesign mr-1"
                @click="exportDefectData()"
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="table role test-plan-table">
        <TableLoader
          v-if="defect_loader"
          :fields="fields"
        />
        <b-row v-else>
          <b-col cols="12">
            <!-- data table start-->

            <b-table
              responsive
              :items="items"
              :fields="fields"
              show-empty
              class="scrollable-element tableDesigns test-table defect-table mastertblColor non-project-table"
            >
              <template #cell(id)="data">
                <p
                  class="tableData cursor-pointer data-hover"
                  style="color:#2178fb "
                  @click="openDefect(data.item.id, 'View')"
                >
                  {{ data.item.code }}
                </p>
              </template>
              <template #cell(defect)="data">
                <p
                  v-b-tooltip.hover.top
                  class="tableData"
                  :title="
                    data.item &&
                      data.item.defect_title &&
                      data.item.defect_title.length > 128
                      ? data.item.defect_title
                      : null
                  "
                >
                  {{ defectTitle(data) }}
                </p>
              </template>
              <template #cell(author)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="
                      data.item.created_by && data.item.created_by.picture
                        ? data.item.created_by.picture
                        : ''
                    "
                    :text="
                      data.item.created_by && data.item.created_by.picture
                        ? data.item.created_by.full_name.charAt(0)
                        : ''
                    "
                  />
                  <p class="ml-50 tableData">
                    {{
                      data.item.created_by && data.item.created_by.full_name
                        ? data.item.created_by.full_name
                        : ""
                    }}
                  </p>
                </div>
              </template>
              <template #cell(assignee)="data">
                <div @click="defectData = data.item">
                  <div
                    v-if="data.item.assignee_user"
                    class="d-flex align-items-center"
                  >
                    <b-avatar
                      :src="
                        data.item.assignee_user &&
                          data.item.assignee_user.picture
                          ? data.item.assignee_user.picture
                          : ''
                      "
                      :text="
                        data.item.assignee_user &&
                          data.item.assignee_user.picture
                          ? data.item.assignee_user.full_name.charAt(0)
                          : ''
                      "
                    />
                    <p class="ml-50 tableData">
                      {{
                        data.item.assignee_user &&
                          data.item.assignee_user.full_name
                          ? data.item.assignee_user.full_name
                          : ""
                      }}
                    </p>
                  </div>
                  <v-select
                    v-else
                    v-model="userId"
                    name="status"
                    label="label"
                    :options="$store.state.app.projectUsers"
                    :disabled="loader"
                    class="select-size-sm select-user-nav"
                    placeholder="Select Assignee"
                    @open="addclass"
                    @close="removeclass"
                  >
                    <template v-slot:option="option">
                      {{ option.full_name }}
                    </template>
                    <template #selected-option="option">
                      <div style="display: flex; align-items: baseline">
                        <span
                          class="select-user-name"
                        >{{ option.full_name }}
                        </span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </template>
              <template #cell(severity)="data">
                <p class="tableData">
                  {{ data.item.severity }}
                </p>
              </template>
              <template #cell(status)="data">
                <b-button
                  v-if="!data.item.showSelect"
                  :class="
                    data.item.status == 'resolve'
                      ? 'passed'
                      : data.item.status == 'close' ||
                        data.item.status == 'not_an_issue'
                        ? 'defect'
                        : 'in-progress'
                  "
                  class="cursor-pointer"
                  @click="toggleStatusDropdown(data.index)"
                >{{
                   data.item.status == "not_an_issue"
                     ? "Not An Issue"
                     : data.item.status == "resolve"
                       ? "Resolved"
                       : data.item.status
                 }}
                  <feather-icon icon="ChevronDownIcon" /></b-button>
                <v-select
                  v-else
                  :ref="'statusDropdown_' + data.index"
                  v-model="newStatus"
                  name="status"
                  label="label"
                  :options="statusOptions"
                  :disabled="loader"
                  class="select-size-sm select-user-nav"
                  :reduce="(statusOptions) => statusOptions.value"
                  :clearable="false"
                  @open="addclass"
                  @close="removeclass(data.index)"
                  @input="changeStatus(data.item)"
                >
                  <template v-slot:option="option">
                    {{ option.label }}
                  </template>
                  <template #selected-option="option">
                    <div style="display: flex; align-items: baseline">
                      <span class="select-user-name">{{ option.label }} </span>
                    </div>
                  </template>
                </v-select>
              </template>

              <template #cell(updated_at)="data">
                <p class="tableData">
                  {{ data.item.updated_at | DateDDMMYYYYFormat }}
                </p>
              </template>
              <template #cell(action)="data">
                <div class="boardDropdown mr-75 text-right">
                  <b-dropdown
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <button class="edit-del-button">
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          size="21"
                          class="align-left edit-data-delete"
                        />
                      </button>
                    </template>
                    <b-dropdown-item
                      class="edit-delete-item"
                      @click="openDefect(data.item.id, 'Edit')"
                    >
                      <span class="edit-data">Edit </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="edit-delete-item"
                      @click="openDefect(data.item.id, 'View')"
                    >
                      <span class="edit-data">View</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="edit-delete-item"
                      @click="deleteConfirmation(data.item.id)"
                    >
                      <span class="edit-data">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- data table complete -->
            <!-- data table complete -->
            <img
              v-if="totalCount == 0"
              src="@/assets/images/nodatafound/nodata.svg"
              alt="no-data"
              class="no-data-img"
            >
            <!-- pagination section -->
          </b-col>
          <!-- pagination section -->
          <b-col
            v-if="totalCount > 0"
            cols="12"
            class="d-flex justify-content-between flex-wrap align-items-center table-pagination"
          >
            <p
              v-if="totalCount > 10"
              class="mt-1"
            >
              Showing {{ 1 + (currentPage - 1) * perPage }} to
              <span v-if="currentPage * perPage < totalCount">{{
                currentPage * perPage
              }}</span>
              <span v-else>{{ totalCount }}</span> out of
              {{ totalCount }} entries
            </p>
            <p
              v-else
              class="mt-1"
            >
              Showing 1 to {{ totalCount }} entries out of
              {{ totalCount }} entries
            </p>
            <!-- <p></p> -->
            <b-pagination
              v-if="totalCount > 0"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <!-- pagination section complete here -->
        </b-row>
      </div>
    </div>
    <!-- Comment modal start -->
    <CommentModal
      ref="commentRef"
      :status="newStatus"
      @updateData="updateStatus"
    />
    <!-- Comment modal end -->
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BAvatar,
} from 'bootstrap-vue'
import TableLoader from '@/components/loaders/table-loading.vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import debounce from 'lodash/debounce'
import OptionDropdown from '../../../components/optiondropdown/OptionDropdown.vue'
import CommentModal from './Modal/CommentModal.vue'

export default {
  name: 'DefeactsTable',
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BAvatar,
    TableLoader,
    customDropdown,
    OptionDropdown,
    CommentModal,
  },
  data() {
    return {
      suite_id: [],
      loader: false,
      defectData: null,
      userId: null,
      totalCount: 0,
      defect_loader: false,
      currentPage: 1,
      perPage: 25,
      loading: false,
      // sortBy: 'code',
      sortBy: 'created_at',
      sortDesc: true,
      is_active: 'active',
      search: null,
      fields: [
        { key: 'id', label: 'Defect ID' },
        {
          key: 'defect',
          label: 'Defect',
          thStyle: { minWidth: '250px', textAlign: 'start' },
        },
        {
          key: 'author',
          label: 'Author',
          thStyle: { minWidth: '245px', textAlign: 'start' },
        },
        {
          key: 'assignee',
          label: 'Assignee',
          thStyle: { minWidth: '250px', textAlign: 'start' },
        },
        {
          key: 'severity',
          label: 'Severity',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'defect_type',
          label: 'type',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'status',
          label: 'Status',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },
        {
          key: 'updated_at',
          label: 'Last Updated Date',
          thStyle: { minWidth: '250px', textAlign: 'start' },
        },
        {
          key: 'action',
          label: 'Action',
          thStyle: { minWidth: '150px', textAlign: 'end' },
        },
      ],
      items: [],
      assigneeUser: [],
      status: ['open', 'reopen'],
      severity: [],
      newStatus: null,
      currentOpenRow: -1,
      defectType: [],
      defectTypeOptions: [
        { label: 'Bug', value: 'bug' },
        { label: 'Improvement', value: 'improvement' },
      ],
    }
  },
  computed: {
    projectUsers() {
      this.$store.state.app.projectUsers.forEach(element => {
        element.label = element.full_name
        element.value = element.id
      })

      const users = [...this.$store.state.app.projectUsers]

      return users.sort((a, b) => a.label.localeCompare(b.label))
    },
    statusOptions() {
      return [
        { value: 'open', label: 'Open' },
        { value: 'reopen', label: 'Reopen' },
        { value: 'resolve', label: 'Resolved' },
        { value: 'not_an_issue', label: 'Not An issue' },
        { value: 'close', label: 'Close' },
      ]
    },
    suiteListOption() {
      const { suiteList } = this.$store.state.app

      suiteList.forEach(element => {
        element.label = element.suite_name
        element.value = element.id
      })
      return suiteList
    },

    queryDataSet() {
      return {
        suiteid: this.suite_id,
        currentPage: this.currentPage,
        perPage: this.perPage,
        search: this.search,
        assignee: this.assigneeUser,
        status: this.status,
        severity: this.severity,
        type: this.defectType,
        layout: this.$route.query.layout,
        pageName: this.$route.query.pageName,
      }
    },

    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.defectList()
        this.queryDataUpdate()
      }, 750),
    },
  },
  watch: {
    currentPage(nv) {
      if (this.$route.query.currentPage !== nv) {
        this.defectList()
      }
      this.queryDataUpdate()
    },
    userId(nv) {
      if (this.defectData && nv) {
        this.updateDefectAssignee(this.defectData)
      }
      this.queryDataUpdate()
    },
    perPage(nv) {
      if (this.$route.query.perPage !== nv) {
        if (this.currentPage == 1) {
          this.defectList()
        } else {
          this.currentPage = 1
        }
      }
      this.queryDataUpdate()
    },
    status() {
      this.queryDataUpdate()
    },
  },
  mounted() {
    const { query } = this.$route
    this.assigneeUser = query.assignee && query.assignee.length
      ? typeof query.assignee === 'string'
        ? [query.assignee]
        : query.assignee
      : []
    this.status = query.status && query.status.length
      ? typeof query.status === 'string'
        ? query.status == 'all' ? ['open', 'reopen', 'resolve', 'not_an_issue', 'close'] : query.status == 'none' ? [] : [query.status]
        : query.status == 'all' ? ['open', 'reopen', 'resolve', 'not_an_issue', 'close'] : query.status
      : this.status
    this.perPage = query.perPage || 25
    this.search = query.search || this.search
    this.suite_id = query.suiteid && query.suiteid.length
      ? typeof query.suiteid === 'string'
        ? [query.suiteid]
        : query.suiteid
      : this.suite_id
    this.currentPage = this.$store.state.app.testActiveName == 'Create'
      ? this.currentPage
    // : this.$store.state.app.testActiveName == 'View'
    // ? 1
      : query.currentPage ? query.currentPage : 1
    this.severity = query.severity && query.severity.length
      ? typeof query.severity === 'string'
        ? [query.severity]
        : query.severity
      : []
    this.defectType = query.type && query.type.length
      ? typeof query.type === 'string'
        ? [query.type]
        : query.type
      : []
    this.defectList()
  },
  methods: {
    queryDataUpdate() {
      this.$router.replace({
        query: {
          suiteid: this.suite_id,
          currentPage: this.currentPage,
          perPage: this.perPage,
          search: this.search,
          assignee: this.assigneeUser,
          status: this.status && this.status.length ? this.status : 'none',
          severity: this.severity,
          type: this.defectType,
          layout: this.$route.query.layout,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    reDirectTab(type, id) {
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: this.$route.params.id4,
          id5: type,
          id6: type !== 'create' ? id : null,
          list_icon: this.$route.params.listIcon,
        },
        query: this.queryDataSet,
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    async defectList() {
      this.queryDataUpdate()
      this.defect_loader = true
      const input = {
        project_id: this.$route.params.id3,
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
        sort_field: this.sortBy,
        sort_order: this.sortDesc ? 'desc' : 'asc',
        status: this.status && this.status.length ? this.status : [],
        assignee:
          this.assigneeUser && this.assigneeUser.length
            ? this.assigneeUser
            : [],
        suite_id: this.suite_id,
        severity: this.severity,
        defect_type: this.defectType,
      }
      const response = await this.getHTTPPostResponse(
        'project/defect',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.items = data.defects

        this.items = data.defects.map(item => ({
          ...item,
          showSelect: false,
        }))

        this.totalCount = data.count
      }
      this.defect_loader = false
    },

    openDefect(id, type) {
      // this.currentPage = type == 'View' ? 1 : this.currentPage;
      this.$store.commit(
        'app/UPDATE_BACK_ACTION',
        this.$store.state.app.pageShow,
      )
      this.$store.state.app.testActiveName = type
      this.$store.state.app.defect.id = id
      this.$store.commit('app/UPDATE_DEFECT_FILTER', {
        assignee:
          this.assigneeUser && this.assigneeUser.length
            ? this.assigneeUser
            : [],
        status: this.status && this.status.length ? this.status : [],
        perPage: this.perPage,
        search: this.search,
        suite_id: this.suite_id,
        currentPage: type === 'View' ? 1 : this.currentPage,
      })
      this.$store.state.app.pageShow = 'create'

      this.reDirectTab(type.toLowerCase(), id)
    },
    /**
     *  Take delete defect confirmation
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p>Once you delete you will not able to recover this record.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteDefect(id)
        }
      })
    },
    /**
     * Delete Defect
     */
    async deleteDefect(id) {
      const response = await this.getHTTPDeleteResponse(
        `project/defect/delete/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.defectList()
        this.search = null
      }
    },

    /**
     * update Assignee Defect
     */
    async updateDefectAssignee(data) {
      this.defect_loader = true
      const formData = {
        defect_title: data.defect_title,
        project_id: this.$route.params.id3,
        severity: data?.severity,
        issue_discription: data?.issue_discription,
        suite_id: data?.suite_id,
        isAssigneeUpdate: true,
        assignee: this.userId && this.userId.id ? this.userId.id : null,
        _method: 'PUT',
      }

      const response = await this.getHTTPPostResponse(
        `project/defect/update/${data.id}`,
        formData,
        true,
      )

      if (response && response.status === 200) {
        this.defectList()
      }
      this.userId = null
      this.defect_loader = false
    },

    applyFilter() {
      this.defectList()
    },
    clearFilter() {
      this.assigneeUser = []
      this.suite_id = []
      this.status = ['open', 'reopen']
      this.perPage = 25
      this.search = null
      this.severity = []
      this.defectType = []
      this.$store.dispatch('app/UPDATE_DEFECT_FILTER')
      this.defectList()
    },
    addclass() {
      // Get the .b-sidebar-body element
      const sidebarBody = document.querySelector('.tableDesigns')
      // Add the new class
      sidebarBody.classList.add('over-x-unset')
    },
    removeclass(index = null) {
      const sidebarBody = document.querySelector('.tableDesigns')
      // remove the  class
      sidebarBody.classList.remove('over-x-unset')
      if (index != -1) {
        this.items[index].showSelect = !this.items[index].showSelect
      }
    },
    toggleStatusDropdown(rowIndex) {
      if (this.currentOpenRow !== -1 && this.currentOpenRow !== rowIndex) {
        // Close the previously open v-select
        this.items && this.items[this.currentOpenRow] && this.items[this.currentOpenRow].showSelect ? this.items[this.currentOpenRow].showSelect = false : null
      }
      this.newStatus = this.items[rowIndex].status
      this.items[rowIndex].showSelect = !this.items[rowIndex].showSelect
      this.currentOpenRow = this.items[rowIndex].showSelect ? rowIndex : -1
    },

    changeStatus(data) {
      this.$store.state.app.defect.id = data.id
      if (this.newStatus) {
        if (this.newStatus == 'reopen' || this.newStatus == 'not_an_issue') {
          this.$root.$emit('bv::toggle::modal', 'comment')
        } else {
          this.updateStatus(this.newStatus)
        }
      }
    },

    async updateStatus(data) {
      let comment
      // let status
      // if (typeof data === 'object') {
      //   comment = data.comment
      //   status = data.status
      // } else {
      //   status = data
      // }
      this.defect_loader = true
      const input = {
        status: this.newStatus ? this.newStatus : data.status,
        comment,
      }
      const response = await this.getHTTPPostResponse(
        `project/defect/status/${this.$store.state.app.defect.id}`,
        input,
        true,
      )

      if (response && response.status == 200) {
        // data.showSelect = false;
        this.$store.state.app.defect.id = null
        setTimeout(() => {
          this.defectList()
        }, 10)
      }
      this.defect_loader = false
    },

    async exportDefectData() {
      this.defect_loader = true

      let URL = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/defect/export-defect?project_id=${this.$route.params.id3}`

      if (this.search) {
        URL += `&search=${this.search}`
      }
      if (this.status && this.status.length) {
        this.status.forEach(element => {
          URL += `&status[]=${element}`
        })
      }
      if (this.suite_id && this.suite_id.length) {
        this.suite_id.forEach(element => {
          URL += `&suite_id[]=${element}`
        })
      }
      if (this.severity && this.severity.length) {
        this.severity.forEach(element => {
          URL += `&severity[]=${element}`
        })
      }

      if (this.assigneeUser && this.assigneeUser.length) {
        this.assigneeUser.forEach(element => {
          URL += `&assignee[]=${element}`
        })
      }

      this.$axios({
        url: URL,
        method: 'GET',
        responseType: 'blob',
      }).then(() => {
        location.href = URL
        this.defect_loader = false
      })
    },

    defectTitle(data){
      return  data.item
                      && data.item.defect_title ?
                        (data.item.defect_title.length < 128
                        ? data.item.defect_title
                        : `${data.item.defect_title.substring(0, 127)  }...`)
                      : null
    }
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
@import "../assets/scss/variables/_variables.scss";

.apply-filter {
  background-color: $secondary-color;
  border: 1px solid $secondary-color;
  color: $white;
  padding: 6px 11px;
  border-radius: 5px;
  font-size: 2 * $rem;
  font-weight: $fw600;

  @media (max-width: map-get($mediaSizes , "xxl")) {
    font-size: 2 * $rem;
  }

  @media (max-width: map-get($mediaSizes , "xl")) {
    font-size: 1.75 * $rem;
  }

  @media (max-width: map-get($mediaSizes , "lg")) {
    font-size: 1.5 * $rem;
  }
}

.apply-filter-clear {
  border: 1px solid $secondary-color;
  color: $secondary-color;
  padding: 6px 20px;
  border-radius: 5px;
  font-size: 2 * $rem;
  font-weight: $fw600;
  background-color: transparent;

  @media (max-width: map-get($mediaSizes , "xxl")) {
    font-size: 2 * $rem;
  }

  @media (max-width: map-get($mediaSizes , "xl")) {
    font-size: 1.75 * $rem;
  }

  @media (max-width: map-get($mediaSizes , "lg")) {
    font-size: 1.5 * $rem;
  }
}
</style>
