<template>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="board-shimmer-flex d-block">
            <div class="board-card-shimmer mw-100">
              <div class="board-shimmer p-0 m-0">
                  <b-skeleton class="right-content m-0"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="board-shimmer-flex d-block">
            <div class="board-card-shimmer mw-100">
              <div class="board-shimmer p-0 m-0">
                  <b-skeleton class="right-content m-0"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="board-shimmer-flex d-block">
            <div class="board-card-shimmer mw-100">
              <div class="board-shimmer p-0 m-0">
                  <b-skeleton class="right-content m-0"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="board-shimmer-flex d-block">
            <div class="board-card-shimmer mw-100">
              <div class="board-shimmer p-0 m-0">
                  <b-skeleton class="right-content m-0"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="board-shimmer-flex d-block">
            <div class="board-card-shimmer mw-100">
              <div class="board-shimmer p-0 m-0">
                  <b-skeleton class="right-content m-0"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  export default {
    name: "TestCaseRunShimmer",
  }
  </script>
  
  <style>
  .board-card-shimmer{
      width: 100% !important;
  }
  .tag {
    width: 40px;
    margin-right: 5px;
  }
  .assign {
    width: 100px;
  }
  .board-shimmer {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    padding: 0;
    max-width: 100%;
  }
  .suit-content1{
      width:100%;
      height: 20px;
  }
  .right-content{
      width:100%;
      height: 40px;
  }
  .board-card-shimmer {
      border-radius: 4px;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  </style>