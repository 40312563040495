<template>
  <div>
    <div>
      <!--filter start-->
      <div class="card-filter-section py-1">
        <div
          class="d-flex align-items-center flex-wrap justify-content-between"
        >
          <div class="d-flex align-items-center">
            <button
              class="back-button btn btn-secondary mr-2"
              @click="createForm()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                width="18"
                height="18"
              />
              <span>Back</span>
            </button>
            <p class="filter-title ml-50">
              {{
                actionTypeCheck
                  ? "Details"
                  : $store.state.app.testActiveName + " Test Plan"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="6">
        <div class="test-form">
          <validation-observer ref="testPlanRules">
            <b-form @submit.prevent>
              <b-form-group
                label="Title"
                label-for="v-title"
                :class="{'required-form-filed':!actionTypeCheck}"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required|max:256"
                >
                  <b-form-input
                    v-show="!actionTypeCheck"
                    id="v-title"
                    v-model="$store.state.app.testPlanFormData.title"
                    type="text"
                    placeholder="Title"
                    :disabled="actionTypeCheck"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <p v-show="actionTypeCheck">
                  {{ $store.state.app.testPlanFormData.title }}
                </p>
              </b-form-group>

              <div>
                <label for="textarea-default">Objective</label>
                <b-form-textarea
                  v-show="!actionTypeCheck"
                  id="textarea-default"
                  v-model="$store.state.app.testPlanFormData.description"
                  placeholder="Add Objective"
                  :disabled="actionTypeCheck"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
                <p v-show="actionTypeCheck">
                  {{ $store.state.app.testPlanFormData.description }}
                </p>
              </div>
            </b-form>
          </validation-observer>
          <div class="test-plan mt-1">
            <p class="title">
              {{
                $store.state.app.testCaseId &&
                  $store.state.app.testCaseId.length
                  ? $store.state.app.testCaseId.length
                  : 0
              }}
              test cases selected
            </p>
            <div
              v-if="$store.state.app.testActiveName != 'View'"
              class="d-flex my-2 align-items-center"
              role="button"
              @click="storeFormData"
            >
              <div class="fill-plus-icon">
                <feather-icon
                  icon="PlusIcon"
                  width="18"
                  height="18"
                  color="#FFFFFF"
                />
              </div>

              <p class="add-step ml-50">
                {{ testPlanId ? $store.state.app.testActiveName : "Add" }} Cases
              </p>
            </div>
            <div
              v-if="$store.state.app.testActiveName != 'View'"
              class="my-2"
            >
              <b-button
                type="submit"
                class="mr-1 save-btn"
                :disabled="loader"
                @click="validationForm"
              ><b-spinner
                 v-if="loader"
                 small
                 class="spinner"
               />
                {{ testPlanId ? "Update" : "Create" }} Plan
              </b-button>
              <b-button
                class="cancelBtn mr-1"
                type="reset"
                @click="createForm()"
              >
                cancel
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="6"
        :class="$store.state.appConfig.layout.type == 'full' ?'right-full-height':' right-fix-height'"
      >
        <TestPlanCasesView v-if="!loader" />
        <TestPlanLoader v-if="loader" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TestPlanCasesView from './TestPlanCasesView.vue'
import TestPlanLoader from './Loader/TestPlanLoader.vue'

export default {
  name: 'CreateTestPlan',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    TestPlanCasesView,
    TestPlanLoader,
  },
  data() {
    return {
      loader: false,
      testPlanId: null,
    }
  },

  computed: {
    actionTypeCheck() {
      if (this.$store.state.app.testActiveName == 'View') {
        return true
      }
      return false
    },
  },
  mounted() {
    const { app } = this.$store.state
    const { id4, id5, id6 } = this.$route.params

    app.backAction = app.backAction || id4
    app.testActiveName = app.testActiveName || id5

    if (app.testActiveName) {
      app.testActiveName = app.testActiveName[0].toUpperCase() + app.testActiveName.slice(1)
    }

    if (id5 !== 'create' && id6) {
      this.testPlanId = id6
      if (this.$store.state.app.testPlanFormData.title == null) this.getTestPlanDetail(id6)
    }
  },
  destroyed() {
    if (this.$route.params.id4 == 'undefined') {
      this.createForm()
    }
  },
  methods: {
    /** back */

    back() {
      this.$store.state.app.pageShow = 'test-plan'
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: 'test-plan',
          id5: null,
          id6: null,
        },
        query: this.$route.query,
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    /**
     * data clear after redirect to the testCase select page
     */
    storeFormData() {
      this.$store.state.app.pageShow = 'selecttestcasesVue'
    },
    /**
     *  check the form validation
     * @returns if success true then call saveGroupDtl Method
     */
    validationForm() {
      this.$refs.testPlanRules.validate().then(success => {
        if (success) {
          if (
            this.$store.state.app.testCaseId
            && this.$store.state.app.testCaseId.length != 0
          ) {
            if (this.testPlanId) {
              this.updateTestPlan()
            } else {
              this.createNewTestPlan()
            }
          } else {
            this.confirmationMessage()
          }
        }
      })
    },

    /**
     * confirmation alert message
     */
    confirmationMessage() {
      this.$swal({
        title: 'Are you sure ?',
        icon: 'info',
        html: 'Please select test case!',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Add test case',
        cancelButtonText: 'Close',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.state.app.pageShow = 'selecttestcasesVue'
        }
      })
    },
    createForm() {
      const obj = {
        title: null,
        description: null,
        testPlanId: null,
        suiteId: [],
      }
      setTimeout(() => {
        
        this.$store.commit('app/UPDATE_TEST_CASE_ID', [])
        this.$store.commit('app/UPDATE_TEST_PLAN_FORM_DATA', obj)
        // this.$store.state.app.backAction = null;
        this.back()
      }, 100)
    },
    /**
     * Create new test plan
     */
    async createNewTestPlan() {
      this.loader = true
      const input = {
        project_id: this.$route.params.id3,
        title: this.$store.state.app.testPlanFormData.title,
        description: this.$store.state.app.testPlanFormData.description,
        test_case_ids: this.$store.state.app.testCaseId,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-plan/create',
        input,
        true,
      )

      if (response && response.status == 200) {
        this.$store.commit('app/UPDATE_TESTPLAN_FILTER', {
          search: null,
          perPage: 25,
          currentPage: 1,
        })
        setTimeout(() => {
          this.createForm()
          this.back()
          this.loader = false
        }, 900)
      } else {
        this.loader = false
      }
    },

    /**
     * Create new test plan
     */
    async updateTestPlan() {
      this.loader = true
      const input = {
        project_id: this.$route.params.id3,
        title: this.$store.state.app.testPlanFormData.title,
        description: this.$store.state.app.testPlanFormData.description,
        test_case_ids: this.$store.state.app.testCaseId,
      }
      const response = await this.getHTTPPutResponse(
        `project/test-plan/update/${this.testPlanId}`,
        input,
        true,
      )

      if (response && response.status == 200) {
        this.createForm()
        this.back()
      }
      this.loader = false
    },

    /** Get Test plan data */
    async getTestPlanDetail(id) {
      this.loader = true
      const response = await this.getHTTPGetResponse(
        `project/test-plan/view/${id}`,
      )
      if (response && response.status == 200) {
        const data = response.data.testPlan
        // this.testPlanId = data.id;
        // this.$store.state.app.testPlanFormData.title = data.title;
        // this.$store.state.app.testPlanFormData.description = data.description;

        const testCaseId = []
        const testSuiteId = []
        data.test_plan_cases.forEach(element => {
          testCaseId.push(element.id)
          testSuiteId.push(element.suite_id)
        })
        // this.$store.state.app.testCaseId = testCaseId;
        const uniqueTestSuiteId = Array.from(new Set(testSuiteId))
        this.$store.commit('app/UPDATE_TEST_PLAN_FORM_DATA', {
          testPlanId: data.id,
          title: data.title,
          description: data.description,
          suiteId: uniqueTestSuiteId,
        })
        this.$store.commit('app/UPDATE_TEST_CASE_ID', testCaseId)
      }
      this.loader = false
    },

    async getSuiteList() {
      const input = {
        project_id: this.$route.params.id3 ? this.$route.params.id3 : null,
      }
      const response = await this.getHTTPPostResponse(
        'project/defect/test-suite-list',
        input,
        false,
      )

      if (response && response.status == 200) {
        const data = response.data && response.data.test_suite
          ? response.data.test_suite
          : []
        this.$store.commit('app/UPDATE_SUITE_LIST', data)
        // let parentChildSuite = this.convertToParentChildData(data);
        this.$store.commit('app/UPDATE_PROJECT_SUITE_LIST', data)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
