<template>
  <div>
    <div>
      <div class="card-filter-section">
        <div class="d-flex align-items-center flex-wrap justify-content-between">
          <div class="d-flex align-items-center justify-content-start flex-wrap">
            <p class="filter-title">
              Test Run
            </p>
          </div>
          <div class="d-flex align-items-center">
            <OptionDropdown v-model="perPage" class="mr-2" />
            <b-input-group class="input-group-merge search-project"
              style="min-width: 250px; max-width: 250px; width: 250px">
              <b-form-input v-model="debouncedSearch" placeholder="Search" />

              <b-input-group-append is-text>
                <feather-icon v-if="debouncedSearch" icon="XIcon" @click="debouncedSearch = null, search = null" />
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>

            <div class="ml-1">
              <button class="create-filter filterRedesign mr-1" @click="openDefect(null, 'Create')">
                Start New Test Run
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="table role test-plan-table">
        <TableLoader v-if="loading" :fields="fields" />
        <b-row v-else>
          <b-col cols="12">
            <!-- data table start-->
            <b-table responsive :items="items" :fields="fields" show-empty
              class="scrollable-element tableDesigns test-table mastertblColor non-project-table">
              <template #cell(title)="data">
                <div class="d-flex align-items-center" style="cursor: pointer"
                  @click="redirectDetailPage(data.item.id, data.item.plan_id)">
                  <img src="../../../assets/images/icons/runTableIocn.svg" class="img-fluid">
                  <div class="run-table-first-column ml-75">
                    <p v-b-tooltip.hover.right class="title1 data-hover"
                      :title="totalCount > 1 ? data.item.run_title : ''">{{ data.item.run_title }}</p>
                    <p class="time">
                      Started {{ data.item.created_at | dateFromNow }} by
                      <a href="#">{{
                        data.item.created_by && data.item.created_by.full_name
                          ? data.item.created_by.full_name
                          : null
                      }}</a>
                    </p>
                  </div>
                </div>
              </template>
              <template #cell(defects_count)="data">
                <p class="ml-1">
                  {{ defectsCount(data.item) }}
                </p>
              </template>
              <template #cell(time)="data">
                <div class="d-flex align-items-center">
                  <img src="../../../assets/images/icons/carbon_time.svg">
                  <p class="ml-50 tableData">
                    <!-- {{ data.item.total_time | hoursInTimeFormat }}
                     -->
                    {{ timeSpend(data.item) | secondsToTime }}
                  </p>
                </div>
              </template>
              <template #cell(status)="data">
                <div>
                  <b-button class="in-progress">{{
                    data.item.status && data.item.status == "in_progress"
                      ? "In Progress "
                      : data.item.status == "start"
                        ? "Start"
                        : "Stop"
                  }}</b-button>
                </div>
              </template>
              <template #cell(action1)="data">
                <div class="d-flex align-items-center">
                  <div class="play-btn" role="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path
                        d="M6.22113 19.1995L6.22113 4.79911C6.22159 4.65331 6.2618 4.5104 6.33744 4.38576C6.41308 4.26111 6.52127 4.15946 6.65039 4.09173C6.7795 4.02401 6.92464 3.99278 7.07018 4.0014C7.21573 4.01003 7.35616 4.05819 7.47637 4.14069L17.8767 11.3409C18.3079 11.6393 18.3079 12.3577 17.8767 12.6569L7.47637 19.8571C7.35641 19.9405 7.21591 19.9894 7.07012 19.9984C6.92434 20.0075 6.77885 19.9765 6.64946 19.9087C6.52007 19.8409 6.41173 19.739 6.33622 19.614C6.26071 19.4889 6.2209 19.3456 6.22113 19.1995Z"
                        fill="#5E5873" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path
                        d="M8.20007 19C9.30007 19 10.2001 18.1 10.2001 17V7C10.2001 5.9 9.30007 5 8.20007 5C7.10007 5 6.20007 5.9 6.20007 7V17C6.20007 18.1 7.10007 19 8.20007 19ZM14.2001 7V17C14.2001 18.1 15.1001 19 16.2001 19C17.3001 19 18.2001 18.1 18.2001 17V7C18.2001 5.9 17.3001 5 16.2001 5C15.1001 5 14.2001 5.9 14.2001 7Z"
                        fill="#5E5873" />
                    </svg>
                  </div>
                  <div class="pause-btn" role="button" @click="
                    startEndTime(data.item.id, data.item.plan_id, 'stoped')
                    ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path
                        d="M7.70007 5.25H16.7001C17.2968 5.25 17.8691 5.48705 18.2911 5.90901C18.713 6.33097 18.9501 6.90326 18.9501 7.5V16.5C18.9501 17.0967 18.713 17.669 18.2911 18.091C17.8691 18.5129 17.2968 18.75 16.7001 18.75H7.70007C7.10334 18.75 6.53104 18.5129 6.10908 18.091C5.68713 17.669 5.45007 17.0967 5.45007 16.5V7.5C5.45007 6.90326 5.68713 6.33097 6.10908 5.90901C6.53104 5.48705 7.10334 5.25 7.70007 5.25Z"
                        fill="#5E5873" />
                    </svg>
                  </div>
                </div>
              </template>
              <template #cell(statusOftask)="data">
                <b-progress v-if="data.item.progress && data.item.progress.length" :max="100">
                  <b-progress-bar v-for="(progress, index) in data.item.progress" :key="index" v-b-tooltip.hover.top
                    :class="progress.label == 'Pending'
                        ? 'blue-box'
                        : progress.label == 'Pass'
                          ? 'green-box'
                          : 'red-box'
                      " :value="progress.value" :label="progress.count.toString()" :title="progress.label
                        ? progress.label == 'Defect'
                          ? 'Failed'
                          : progress.label
                        : null
                      " show-progress />
                </b-progress>
                <b-progress v-else :max="100" />
              </template>
              <template #cell(action)="data">
                <div class="boardDropdown mr-75 text-right">
                  <b-dropdown toggle-class="text-decoration-none" no-caret boundary="viewport" dropleft
                    text="Drop-Left">
                    <template v-slot:button-content>
                      <button class="edit-del-button">
                        <feather-icon icon="MoreHorizontalIcon" size="21" class="align-left edit-data-delete" />
                      </button>
                    </template>
                    <b-dropdown-item class="edit-delete-item" @click="openDefect(data.item.id, 'Edit')">
                      <span class="edit-data">Edit </span>
                    </b-dropdown-item>
                    <b-dropdown-item class="edit-delete-item" @click="openDefect(data.item.id, 'View')">
                      <span class="edit-data">View</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="edit-delete-item" @click="deleteConfirmation(data.item.id)">
                      <span class="edit-data">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- data table complete -->
            <img v-if="totalCount == 0" src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img">
          </b-col>
          <!-- pagination section -->
          <b-col v-if="totalCount > 0" cols="12"
            class="d-flex justify-content-between flex-wrap align-items-center table-pagination">
            <p v-if="totalCount > 10" class="mt-1">
              Showing {{ 1 + (currentPage - 1) * perPage }} to
              <span v-if="currentPage * perPage < totalCount">{{
                currentPage * perPage
                }}</span>
              <span v-else>{{ totalCount }}</span> out of
              {{ totalCount }} entries
            </p>
            <p v-else class="mt-1">
              Showing 1 to {{ totalCount }} entries out of
              {{ totalCount }} entries
            </p>
            <!-- <p></p> -->
            <b-pagination v-if="totalCount > 0" v-model="currentPage" :total-rows="totalCount" :per-page="perPage"
              first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <!-- pagination section complete here -->
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BProgressBar,
  BProgress,
} from 'bootstrap-vue'
import TableLoader from '@/components/loaders/table-loading.vue'
import * as moment from 'moment/moment'
import debounce from 'lodash/debounce'
import OptionDropdown from '../../../components/optiondropdown/OptionDropdown.vue'

export default {
  name: 'TestRunTable',
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    TableLoader,
    BProgressBar,
    BProgress,
    OptionDropdown,
  },
  data() {
    return {
      max: 100,
      totalCount: 1,
      currentPage: 1,
      perPage: 25,
      loading: false,
      sortBy: 'created_at',
      sortDesc: true,
      is_active: 'active',
      search: null,
      fields: [
        {
          key: 'title',
          label: 'Title',
          thStyle: { minWidth: '400px', textAlign: 'start' },
        },
        {
          key: 'defects_count',
          label: 'Defect',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'time',
          label: 'Time Spent',
          thStyle: { minWidth: '160px', textAlign: 'start' },
        },
        // {
        //   key: "status",
        //   label: "Status",
        //   thStyle: { minWidth: "230px", textAlign: "start" },
        // },
        // {
        //   key: "action1",
        //   label: "Action",
        //   thStyle: { minWidth: "150px", textAlign: "start" },
        // },
        {
          key: 'statusOftask',
          label: 'Status',
          thStyle: { minWidth: '500px', textAlign: 'center' },
        },
        {
          key: 'action',
          label: 'Action',
          thStyle: { minWidth: '150px', textAlign: 'end' },
        },
      ],
      items: [],
    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.testRunList()
        this.queryDataUpdate()
      }, 750),
    },
  },
  watch: {
    currentPage(nv) {
      if (this.$route.query.currentPage !== nv) {
        this.testRunList()
      }
      this.queryDataUpdate()
    },
    perPage(nv) {
      if (this.$route.query.perPage !== nv) {
        if (this.currentPage == 1) { this.testRunList() } else this.currentPage = 1
      }

      this.queryDataUpdate()
    },
  },
  mounted() {
    this.setFilterValue()
    const unwatch = this.$watch('perPage')

    unwatch()
    this.testRunList()
  },

  // destroyed() {
  //   if(this.$store.state.app.activeTab !== 'TestRunTableVue') {
  //     this.$store.dispatch('app/UPDATE_TESTRUN_FILTER');
  //   }
  // },

  methods: {
    queryDataUpdate() {
      const query = {
        currentPage: this.currentPage,
        perPage: this.perPage,
        search: this.search,
        layout: this.currentLayout,
        pageName: this.$route.query.pageName,
      }
      this.routeQueryUpdate(query)
    },
    setFilterValue() {
      if (this.$route.query) {
        this.search = this.$route.query.search
        this.perPage = this.$route.query.perPage
          ? this.$route.query.perPage
          : 25
        this.currentPage = this.$route.query.currentPage
          ? this.$route.query.currentPage
          : 1
      } else {
        this.search = null
        this.perPage = 25
        this.currentPage = 1
      }
    },
    timeSpend(data) {
      const testRunCases = data?.test_run_cases || []
      let total_time = 0
      testRunCases.forEach(element => {
        total_time += element.total_time ? element.total_time : 0
      })

      return total_time * 60
    },
    openDefect(id, type) {
      this.$store.state.app.testRun.id = id
      this.$store.state.app.testActiveName = type
      this.$store.commit('app/UPDATE_TESTRUN_FILTER', {
        search: this.search,
        perPage: this.perPage,
        currentPage: this.currentPage,
      })
      this.reDirectTab(type.toLowerCase(), id)
    },

    reDirectTab(type, id) {
      this.$store.state.app.pageShow = 'create'
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: this.$route.params.id4,
          id5: type,

          id6: type !== 'create' ? id : null,
        },
        query: {
          currentPage: this.currentPage,
          perPage: this.perPage,
          search: this.search,
          layout: this.currentLayout,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

    async testRunList() {
      this.loading = true
      const input = {
        project_id: this.$route.params.id3,
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
        sort_field: this.sortBy,
        sort_order: this.sortDesc ? 'desc' : 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'project/test-run',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        data.testRuns.forEach(element => {
          let arr = []

          const total_Test_Case = element.test_run_cases.length
          const pending = element.test_run_cases.filter(
            e => e.status == 'pending',
          )

          // let in_progress = element.test_run_cases.filter((e) => {
          //   e.status == 'in_progress'
          // });
          const defect = element.test_run_cases.filter(
            e => e.status == 'defect',
          )

          const pass = element.test_run_cases.filter(e => e.status == 'passed')

          arr = [
            {
              label: 'Pending',
              value: pending.length
                ? (pending.length / total_Test_Case) * 100
                : 0,
              count: pending.length,
            },
            {
              label: 'Defect',
              value: defect.length
                ? (defect.length / total_Test_Case) * 100
                : 0,
              count: defect.length,
            },
            {
              label: 'Pass',
              value: pass.length ? (pass.length / total_Test_Case) * 100 : 0,
              count: pass.length,
            },
          ]

          element.progress = arr
        })
        this.items = data.testRuns
        this.totalCount = data.count
      }
      this.loading = false
    },
    /**
     *  Take delete defect confirmation
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p>Once you delete you will not able to recover this record.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteDefect(id)
        }
      })
    },
    /**
     * Delete Defect
     */
    async deleteDefect(id) {
      const response = await this.getHTTPDeleteResponse(
        `project/test-run/delete/${id}`,
        {},
        true,
      )
      if (response && response.status == 200) {
        this.testRunList()
        this.search = null
      }
    },

    redirectDetailPage(id, plan_id) {
      this.$store.state.app.test_run.start_time = moment(new Date()).format(
        'HH:mm:ss',
      )

      this.$store.state.app.test_run.plan_id = plan_id
      this.$store.state.app.test_run.id = id
      this.$store.commit('app/UPDATE_TESTRUN_FILTER', {
        search: this.search,
        perPage: this.perPage,
      })
      this.$store.state.app.pageShow = 'testCasesTableVue'
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: this.$route.params.id4,
          id5: id,
          id6: plan_id,
        },
        query: {
          currentPage: this.currentPage,
          perPage: this.perPage,
          search: this.search,
          layout: this.currentLayout,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    defectsCount(data) {
      const testRunCases = data?.test_run_cases || []
      let total_time = 0
      testRunCases.forEach(element => {
        total_time += element.defects_count ? element.defects_count : 0
      })
      return total_time
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
