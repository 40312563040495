<template>
  <!-- this form is use in project table  -->
  <div class="custom-project-form">
    <validation-observer ref="simpleRules">
      <b-sidebar id="addMilestone" bg-variant="white" right backdrop shadow class="sidebar-form add-sprint"
        @hidden="sidebarToggle()">
        <!-- @hidden="sidebarToggle" -->
        <!--  form start -->

        <h3 class="form-title">Add Milestone</h3>
        <!-- <p class="project-name mb-2">HPV Project</p> -->
        <div class="b-sidebar-header">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Title" label-for="Title" class="required-form-filed">
                <validation-provider #default="{ errors }" name="Title" rules="required">
                  <b-form-input id="Title" v-model="title" type="text" name="milestoneTitle"
                    placeholder="Milestone Title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group label="Description" label-for="Description" class="required-form-filed">
            <validation-provider #default="{ errors }" name="Description" rules="required">
              <b-form-textarea id="Description" v-model="description" size="sm" rows="5" placeholder="Description"
                name="Description"></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Actual start & end date input -->
          <!-- <b-row>
                        <b-col xl="6" md="12">
                            <b-form-group>
                                <label for="actual_start_date">Actual start date</label>
                                <validation-provider #default="{ errors }" name="Actual start date">
                                    <b-input-group class="input-group-merge">
                                        <flat-pickr class="form-control" :config="configActualStartDate" v-model="actual_start_date" placeholder="Actual start date" @on-change="onActualStartDateChange" name="actual_start_date" />
                                        <b-input-group-append is-text>
                                            <feather-icon icon="CalendarIcon" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        
                        <b-col xl="6" md="12">
                            <b-form-group>
                                <label for="actual_end_date">Actual end date</label>
                                <validation-provider #default="{ errors }" name="Actual end date">
                                    <b-input-group class="input-group-merge">
                                        <flat-pickr class="form-control" :config="configActualEndDate" v-model="actual_end_date" placeholder="Actual end date" @on-change="onActualEndDateChange" name="actual_end_date" />
                                        <b-input-group-append is-text>
                                            <feather-icon icon="CalendarIcon" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row> -->
          <b-row>
            <!-- Estimated start date input -->
            <b-col xl="6" md="12">
              <b-form-group>
                <label for="estimated_start_date">Estimated start date <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Estimated start date" rules="required">
                  <b-input-group class="input-group-merge">
                    <flat-pickr v-model="estimated_start_date" class="form-control" :config="configEstimatedStartDate"
                      placeholder="Estimated start date" name="estimated_start_date"
                      @on-change="onEstimatedStartDateChange" />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Estimated end date input -->
            <b-col xl="6" md="12">
              <b-form-group>
                <label for="estimated end date">Estimated end date <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Estimated end date" rules="required">
                  <b-input-group class="input-group-merge">
                    <flat-pickr v-model="estimated_end_date" class="form-control" :config="configEstimatedEndDate"
                      placeholder="Estimated end date" name="estimated_end_date"
                      @on-change="onEstimatedEndDateChange" />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- <b-row >
                        <b-col cols="12" md="6">
                            <b-form-group label="Cost" label-for="Cost">
                                <validation-provider #default="{ errors }" name="Milestone cost">
                                    <b-input-group id="timeestimate" append="Euro" class="input-group-merge">
                                        <b-form-input type="number" name="MilestoneCost" id="Cost" placeholder="Cost" v-model="cost" />
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row> -->
        </div>
        <!-- form footer -->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button class="btn side-bar-btn" type="submit" :disabled="btnLoader" @click.prevent="validationForm">
              <b-spinner v-if="btnLoader" small class="spinner"></b-spinner>
              Add
            </button>
            <button class="btn side-bar-btn" @click="clearData()">Clear</button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BSidebar,
  BCol,
  BRow,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required } from "@validations"
import flatPickr from "vue-flatpickr-component"
import { eventBus } from "@/main"

export default {
  name: "AddMilestone",
  components: {
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    flatPickr,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    milestoneCount: {
      type: [String,Number],
      required: false,
      default: null
    },
  },
  data() {
    return {
      required,
      name: "",
      btnLoader: false,
      status: null,
      // cost: null,
      title: null,
      description: null,
      projectType: { title: "Public" },
      message: "",
      estimated_start_date: null,
      estimated_end_date: null,
      // actual_start_date: null,
      // actual_end_date: null,
      configEstimatedStartDate: {
        // minDate: new Date(),
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      configEstimatedEndDate: {
        minDate: new Date(),
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      configActualStartDate: {
        //   minDate: new Date(),
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      configActualEndDate: {
        minDate: new Date(),
        maxDate: null,
        dateFormat: "Y/m/d",
      },
    }
  },

  watch: {
    milestoneCount(val) {
      setTimeout(() => {
        this.title = val
          ? `Milestone-${val + 1}`
          : `Milestone-1`
      }, 200)
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.milestoneCount) {
        this.title = this.milestoneCount
          ? `Milestone-${this.milestoneCount + 1}`
          : `Milestone-1`
      }
    }, 200)
  },
  methods: {
    onEstimatedStartDateChange(selectedDates, dateStr) {
      this.$set(this.configEstimatedEndDate, "minDate", dateStr)
    },
    onEstimatedEndDateChange(selectedDates, dateStr) {
      this.$set(this.configEstimatedStartDate, "maxDate", dateStr)
    },
    // onActualStartDateChange(selectedDates, dateStr) {
    //     this.$set(this.configActualEndDate, "minDate", dateStr);
    // },
    // onActualEndDateChange(selectedDates, dateStr) {
    //     this.$set(this.configActualStartDate, "maxDate", dateStr);
    // },

    /* sidebarToggle for clear data */
    sidebarToggle() {
      // this.cost = null;
      this.projectType = null
      this.description = null
      this.estimated_start_date = null
      this.estimated_end_date = null
      // this.actual_start_date = null;
      // this.actual_end_date = null;
      this.$refs.simpleRules.reset()
    },
    /**
     * Clear form details on click clear button
     */
    clearData() {
      // this.cost = null;
      this.title = this.milestoneCount
        ? `Milestone-${this.milestoneCount + 1}`
        : `Milestone-1`
      this.projectType = null
      this.description = null
      this.estimated_start_date = null
      this.estimated_end_date = null
      // this.actual_start_date = null;
      // this.actual_end_date = null;
      this.$refs.simpleRules.reset()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.saveMilestonedata()
        }
      })
    },
    /**
     * save the client form details
     */
    async saveMilestonedata() {
      this.btnLoader = true
      const input = {
        project_id: this.$route.params.id3,
        type: "milestone",
        title: this.title,
        description: this.description,
        estimated_start_date: this.estimated_start_date,
        estimated_end_date: this.estimated_end_date,
        // actual_start_date: this.actual_start_date,
        // actual_end_date: this.actual_end_date,
        // cost: this.cost,
      }

      let response = null

      response = await this.getHTTPPostResponse(
        "project/plan/create",
        input,
        true
      )

      if (response && response.status == 200) {
        this.clearData()
        this.$root.$emit("bv::toggle::collapse", "addMilestone")
        eventBus.$emit("milestoneList", true)
        this.btnLoader = false
      } else {
        this.btnLoader = false
      }
    },
  },
}
</script>

<style>
@import "../../../assets/scss/component-css/masterplan.css";
</style>
